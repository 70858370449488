'use client'

import { isBrightColor } from '@havppen/utils/src/color'
import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'
import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import styled from 'styled-components'

type TagListTypeProps = {
  size?: 'small' | 'default' | 'large'
  type?: 'light' | 'dark'
  maxSize?: number
  bordered?: boolean
}

const StyledTagListDiv = styled.div`
  .tag {
    color: var(--theme-primary-color, #000);
    display: inline-block;
    font-size: 0.7rem;

    @media screen and (min-width: ${BREAK_POINT}px) {
      font-size: 0.8rem;
    }
  }
  .light {
    transition: 0.5s color;
    &:hover {
      color: var(--theme-title-hover-color);
    }
  }
  .bordered {
    border: 1px solid var(--theme-border-color);
  }
  .dark {
    background-color: ${props => (isBrightColor(props.theme['primary-color']) ? '#343a40' : '#f5f5f5')};
  }
  .size {
    &__small {
      font-size: 0.6rem;
      @media screen and (min-width: ${BREAK_POINT}px) {
        font-size: 0.7rem;
      }
    }
    &__large {
      font-size: 0.8rem;
      @media screen and (min-width: ${BREAK_POINT}px) {
        font-size: 0.9rem;
      }
    }
  }
`

const TagListBox: React.FC<TagListTypeProps & { tagNames: string[] }> = ({
  tagNames,
  type = 'light',
  size = 'default',
  maxSize = 4,
  bordered = true,
}) => {
  return (
    <StyledTagListDiv>
      {tagNames.slice(0, maxSize).map((tagName, index) => {
        const tagPath = `/tag/${encodeURIComponent(tagName)}`

        return (
          <Link
            key={index}
            href={tagPath}
            className={clsx(
              `tag mb-1 ${type === 'light' ? 'mr-2 py-1 px-2 light' : 'mr-3 py-1 px-2 dark'}`,
              `size__${size}`,
              {
                bordered,
              },
            )}
          >
            <meta itemProp="keywords" content={tagName} />
            <span className="mr-1">#</span>
            <span>{tagName}</span>
          </Link>
        )
      })}
    </StyledTagListDiv>
  )
}

export default TagListBox
