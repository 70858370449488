'use client'

import React from 'react'
import { AppointmentBriefProps } from 'src/hooks/appointment.client'
import ProductCard, { ProductCardContent } from '../common/ProductCard'

const AppointmentCard: React.FC<{
  appointment: AppointmentBriefProps
  showFavorite?: boolean
  openInNewTab?: boolean
  priority?: boolean
  isVisible?: boolean
}> = ({ appointment, showFavorite, openInNewTab, priority, isVisible, ...props }) => {
  return (
    <ProductCard
      productType="Appointment"
      productTarget={appointment.id}
      productStatus={appointment.status}
      featuredImageUrl={appointment.featuredImageUrl}
      title={appointment.title}
      path={appointment.path}
      members={appointment.author ? [appointment.author] : []}
      openInNewTab={openInNewTab}
      showFavorite={showFavorite}
      priority={priority}
      isAuthorInfoHidden={appointment.isAuthorInfoHidden}
      isVisible={isVisible}
    >
      <ProductCardContent
        listPrice={appointment.listPrice}
        averageRating={appointment.averageRating}
        reviewCount={appointment.reviewCount}
        purchaseCount={appointment.purchaseCount}
        isPriceHidden={appointment.isPriceHidden}
        isParticipantCountHidden={appointment.isParticipantCountHidden}
        isDiscountCountdownVisible={appointment.isDiscountCountdownVisible}
      />
    </ProductCard>
  )
}

export default AppointmentCard
