import { defineMessages } from '@formatjs/intl'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { PageElementType } from './elements'
import ActivityListSection from './elements/ActivityListSection/ActivityListSection.toolize'
import AppointmentListSection from './elements/AppointmentListSection/AppointmentListSection.toolize'
import ArticleListSection from './elements/ArticleListSection/ArticleListSection.toolize'
import CarouselPageSection from './elements/CarouselPageSection/CarouselPageSection.toolize'
import CourseListSection from './elements/CourseListSection/CourseListSection.toolize'
import CustomTextSection from './elements/CustomTextSection/CustomTextSection.toolize'
import ImageSection from './elements/ImageSection/ImageSection.toolize'
import InstructorListSection from './elements/InstructorListSection/InstructorListSection.toolize'
import Layout from './elements/Layout/Layout.toolize'
import HighlightSection from './elements/HighlightSection/HighlightSection.toolize'
import MerchandiseListSection from './elements/MerchandiseListSection/MerchandiseListSection.toolize'

const InstructorListSectionSetting = dynamic(
  () => import('./elements/InstructorListSection/InstructorListSection.settings'),
)
const AppointmentListSectionSettings = dynamic(
  () => import('./elements/AppointmentListSection/AppointmentListSection.settings'),
)
const CarouselPageSectionSettings = dynamic(() => import('./elements/CarouselPageSection/CarouselPageSection.settings'))
const MerchandiseListSectionSettings = dynamic(
  () => import('./elements/MerchandiseListSection/MerchandiseListSection.settings'),
)
const ActivityListSectionSettings = dynamic(() => import('./elements/ActivityListSection/ActivityListSection.settings'))
const ArticleListSectionSettings = dynamic(() => import('./elements/ArticleListSection/ArticleListSection.settings'))
const CourseListSectionSettings = dynamic(() => import('./elements/CourseListSection/CourseListSection.settings'))
const CustomTextSectionSettings = dynamic(() => import('./elements/CustomTextSection/CustomTextSection.settings'))
const ImageSectionSettings = dynamic(() => import('./elements/ImageSection/ImageSection.settings'))
const ListSectionSettings = dynamic(() => import('./elements/ListSection/ListSection.settings'))
const LayoutSettings = dynamic(() => import('./elements/Layout/Layout.settings'))
const HighlightSectionSettings = dynamic(() => import('./elements/HighlightSection/HighlightSection.settings'))

const intlMessages = defineMessages({
  CourseListSection: {
    id: 'pages.editor.element.CourseListSection',
    defaultMessage: '課程列表',
  },
  ActivityListSection: {
    id: 'pages.editor.element.ActivityListSection',
    defaultMessage: '活動列表',
  },
  AppointmentListSection: {
    id: 'pages.editor.element.AppointmentListSection',
    defaultMessage: '預約列表',
  },
  MerchandiseListSection: {
    id: 'pages.editor.element.MerchandiseListSection',
    defaultMessage: '商品列表',
  },
  ArticleListSection: {
    id: 'pages.editor.element.ArticleListSection',
    defaultMessage: '文章列表',
  },
  InstructorListSection: {
    id: 'pages.editor.element.InstructorListSection',
    defaultMessage: '老師列表',
  },
  CarouselPageSection: {
    id: 'pages.editor.element.CarouselPageSection',
    defaultMessage: '輪播區塊',
  },
  ImageSection: {
    id: 'pages.editor.element.ImageSection',
    defaultMessage: '圖片區塊',
  },
  CustomTextSection: {
    id: 'pages.editor.element.CustomTextSection',
    defaultMessage: '自訂文字區塊',
  },
  HighlightSection: {
    id: 'pages.editor.element.HighlightSection',
    defaultMessage: '特色區塊',
  },
  Layout: {
    id: 'pages.editor.element.Layout',
    defaultMessage: '頁面',
  },
})
export const useElementResolver = () => {
  const { formatMessage } = useIntl()

  const resolver: Record<string, React.ElementType> = useMemo(() => {
    CourseListSection.craft = {
      name: 'CourseListSection',
      displayName: formatMessage(intlMessages.CourseListSection),
      related: { settings: CourseListSectionSettings },
      isCanvas: false,
      custom: {
        type: 'list' as PageElementType,
      },
    }
    ActivityListSection.craft = {
      name: 'ActivityListSection',
      displayName: formatMessage(intlMessages.ActivityListSection),
      related: { settings: ActivityListSectionSettings },
      isCanvas: false,
      custom: {
        type: 'list' as PageElementType,
      },
    }
    AppointmentListSection.craft = {
      name: 'AppointmentListSection',
      displayName: formatMessage(intlMessages.AppointmentListSection),
      related: { settings: AppointmentListSectionSettings },
      isCanvas: false,
      custom: {
        type: 'list' as PageElementType,
      },
    }
    MerchandiseListSection.craft = {
      name: 'MerchandiseListSection',
      displayName: formatMessage(intlMessages.MerchandiseListSection),
      related: { settings: MerchandiseListSectionSettings },
      isCanvas: false,
      custom: {
        type: 'list' as PageElementType,
      },
    }
    ArticleListSection.craft = {
      name: 'ArticleListSection',
      displayName: formatMessage(intlMessages.ArticleListSection),
      related: { settings: ArticleListSectionSettings },
      isCanvas: false,
      custom: {
        type: 'list' as PageElementType,
      },
    }
    InstructorListSection.craft = {
      name: 'InstructorListSection',
      displayName: formatMessage(intlMessages.InstructorListSection),
      related: { settings: InstructorListSectionSetting },
      isCanvas: false,
      custom: {
        type: 'list' as PageElementType,
      },
    }
    CarouselPageSection.craft = {
      name: 'CarouselPageSection',
      displayName: formatMessage(intlMessages.CarouselPageSection),
      related: { settings: CarouselPageSectionSettings },
      isCanvas: false,
      custom: {
        type: 'basic' as PageElementType,
      },
    }
    ImageSection.craft = {
      name: 'ImageSection',
      displayName: formatMessage(intlMessages.ImageSection),
      related: { settings: ImageSectionSettings },
      isCanvas: false,
      custom: {
        type: 'basic' as PageElementType,
      },
    }
    CustomTextSection.craft = {
      name: 'CustomTextSection',
      displayName: formatMessage(intlMessages.CustomTextSection),
      related: { settings: CustomTextSectionSettings },
      isCanvas: false,
      custom: {
        type: 'basic' as PageElementType,
        isInlineEditable: true,
      },
    }
    HighlightSection.craft = {
      name: 'HighlightSection',
      displayName: formatMessage(intlMessages.HighlightSection),
      related: { settings: HighlightSectionSettings },
      isCanvas: false,
      custom: {
        type: 'basic' as PageElementType,
      },
    }
    Layout.craft = {
      name: 'Layout',
      displayName: formatMessage(intlMessages.Layout),
      related: { settings: LayoutSettings },
      isCanvas: true,
      custom: {
        type: 'basic' as PageElementType,
        hideInToolbar: true,
      },
    }

    return {
      CarouselPageSection,
      CourseListSection,
      ActivityListSection,
      AppointmentListSection,
      MerchandiseListSection,
      InstructorListSection,
      ArticleListSection,
      CustomTextSection,
      ImageSection,
      HighlightSection,
      Layout,
    }
  }, [formatMessage])

  return resolver
}
