'use client'

import { defineMessages } from '@formatjs/intl'
import { Button } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import CardListBlock from 'src/components/blockLayout/CardListBlock'
import PageSection from 'src/components/blockLayout/PageSection'
import InstructorCard from 'src/components/course/InstructorCard'
import { MemberPublicBriefProps, MemberPublicDetailProps } from 'src/types/member'
import { ListSectionProps } from '../ListSection/ListSection'

export type InstructorListSectionProps = ListSectionProps & {
  subtitle?: string
  loadingMembers?: boolean
  members?: MemberPublicBriefProps[]
}

const intlMessages = defineMessages({
  title: {
    id: 'components.HomeInstructorsSection.title',
    defaultMessage: '超強師資',
  },
  subtitle: {
    id: 'components.HomeInstructorsSection.subtitle',
    defaultMessage: '訂閱實力超優的老師，讓你快速達到目標',
  },
  showMore: {
    id: 'components.HomeInstructorsSection.showMore',
    defaultMessage: '顯示更多老師',
  },
})

const InstructorListSection: React.FC<InstructorListSectionProps> = ({
  loadingMembers,
  members = [],
  responsiveOptions,
  title,
  subtitle,
  buttonText,
  buttonPosition = 'bottom',
  buttonSize = 'middle',
  listAlignment = 'alignLeft',
}) => {
  const { formatMessage } = useIntl()
  if (!loadingMembers && members.length === 0) {
    return null
  }

  return (
    <PageSection
      loading={loadingMembers}
      center
      title={title || formatMessage(intlMessages.title)}
      subtitle={subtitle || formatMessage(intlMessages.subtitle)}
      extra={
        buttonPosition !== 'bottom' ? (
          <Button href="/instructors" shape="round" size={buttonSize}>
            <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
            <span className="ml-1">»</span>
          </Button>
        ) : undefined
      }
      extraPosition={buttonPosition === 'bottom' ? undefined : buttonPosition}
    >
      <CardListBlock
        className="mt-3 mt-sm-4"
        responsiveOptions={responsiveOptions}
        listAlignment={listAlignment}
        loading={loadingMembers}
        dataSource={members}
        render={member => <InstructorCard member={member as MemberPublicDetailProps} />}
      />

      {buttonPosition === 'bottom' && (
        <div className="text-center mt-4">
          <Button href="/instructors" shape="round" size={buttonSize}>
            <div className="px-3">
              <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
              <span className="ml-1">»</span>
            </div>
          </Button>
        </div>
      )}
    </PageSection>
  )
}

export default InstructorListSection
