'use client'

import { useArticles } from 'src/hooks/article.client'
import { toolize } from '../..'
import ArticleListSection, { ArticleListSectionProps } from './ArticleListSection'

const ArticleListSectionToolize: React.FC<ArticleListSectionProps> = ({ articleCategoryId, ...props }) => {
  const { articles, loadingArticles } = useArticles({
    limit: 4,
    orderBy: 'latest',
    categoryId: articleCategoryId,
  })

  return <ArticleListSection loadingArticles={loadingArticles} articles={articles} {...props} />
}

export default toolize(ArticleListSectionToolize)
