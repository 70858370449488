'use client'

import { useCarousel } from 'src/hooks/carousel.client'
import { toolize } from '../..'
import CarouselPageSection, { CarouselPageSectionProps } from './CarouselPageSection'

const CarouselPageSectionToolize: React.FC<CarouselPageSectionProps> = ({ carouselId, type, block, ...props }) => {
  const { loadingCarousel, carousel } = useCarousel({ carouselId, type, block, skip: !carouselId && !type && !block })
  return (
    <CarouselPageSection carouselId={carouselId} loadingCarousel={loadingCarousel} carousel={carousel} {...props} />
  )
}

export default toolize(CarouselPageSectionToolize)
