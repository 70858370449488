import { gql, useQuery } from '@apollo/client'
import types from '@havppen/gql/types'
import { useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { GET_CAROUSEL } from 'src/gql/carousel'
import { CarouselBlock, CarouselBriefProps, CarouselProps, CarouselType } from 'src/types/carousel'
import { CarouselQueryOptions, formatGqlCarousel, mapCarouselQueryOptionsToGqlVariables } from './carousel'

export const useCarousel = (options: CarouselQueryOptions) => {
  const { id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_CAROUSEL, types.GET_CAROUSELVariables>(GET_CAROUSEL, {
    skip: options.skip,
    variables: mapCarouselQueryOptionsToGqlVariables(options, { appId }),
  })

  const carousel: CarouselProps | null = useMemo(() => {
    return formatGqlCarousel(data)
  }, [data])

  return {
    loadingCarousel: loading,
    errorCarousel: error,
    refetchCarousel: refetch,
    carousel,
  }
}

export const useCarousels = () => {
  const { id: appId } = useApp()
  const { loading, error, data, refetch } = useQuery<types.GET_ALL_CAROUSELS, types.GET_ALL_CAROUSELSVariables>(
    gql`
      query GET_ALL_CAROUSELS($condition: carousel_bool_exp!) {
        carousel(where: $condition, order_by: { position: asc }) {
          id
          name
          type
          block
          status
        }
      }
    `,
    {
      variables: {
        condition: {
          app_id: { _eq: appId },
        },
      },
    },
  )

  const carousels: CarouselBriefProps[] = useMemo(() => {
    return (data?.carousel || []).map(carousel => ({
      id: carousel.id,
      name: carousel.name,
      type: carousel.type as CarouselType,
      block: carousel.block as CarouselBlock,
      status: carousel.status,
    }))
  }, [data])

  return {
    loadingCarousels: loading,
    errorCarousels: error,
    refetchCarousels: refetch,
    carousels,
  }
}
