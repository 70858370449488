'use client'

import { Button } from 'antd'
import Image from 'next/legacy/image'
import React from 'react'
import { useApp } from 'src/contexts/AppContext'
import styled from 'styled-components'

const StyledDiv = styled.div`
  .start-class-button {
    width: 160px;
  }
  color: var(--theme-secondary-color);
`

const StyledButton = styled(Button)`
  color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);
`

const CallToAction: React.FC<{ title?: string; buttonText?: string; buttonHref?: string; hideButton?: boolean }> = ({
  title,
  buttonText,
  buttonHref,
  hideButton,
}) => {
  const { name: appName } = useApp()

  return (
    <StyledDiv className="text-center mt-3">
      <div>{title ? title : `邀你一同成為 ${appName} 老師行列`}</div>

      <StyledButton
        shape="round"
        size="large"
        className="start-class-button mt-3 mb-4 mx-auto"
        href={buttonHref}
        hidden={hideButton}
      >
        {buttonText ? buttonText : '我要開課'} »
      </StyledButton>

      <div>
        <Image alt={buttonText} src="/image/openCourse.png" width={545} height={409} className="mx-auto" />
      </div>
    </StyledDiv>
  )
}

export default CallToAction
