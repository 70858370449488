'use client'

import { useAppointments } from 'src/hooks/appointment.client'
import { toolize } from '../..'
import AppointmentListSection, { AppointmentListSectionProps } from './AppointmentListSection'

const AppointmentListSectionToolize: React.FC<AppointmentListSectionProps> = ({ categoryId, ...props }) => {
  const { loadingAppointments, appointments } = useAppointments({ categoryId, limit: 4 })

  return <AppointmentListSection loadingAppointments={loadingAppointments} appointments={appointments} {...props} />
}
export default toolize(AppointmentListSectionToolize)
