'use client'

import { Skeleton } from 'antd'
import dynamic from 'next/dynamic'
import React, { memo } from 'react'
import { SpacedCarouselProps } from 'src/components/common/carousel/SpacedCarousel'
import { Responsive } from 'src/contexts/ResponsiveContext'
import styled from 'styled-components'

const StyledDesktopBlock = styled.div`
  display: flex;

  .item {
    margin: 0 8px;
    flex: 0 0 calc(100% / var(--grid-column, 2) - 16px);
    @media (min-width: 960px) {
      flex: 0 0 calc(100% / var(--grid-column-lg, 3) - 16px);
    }
    @media (min-width: 1200px) {
      flex: 0 0 calc(100% / var(--grid-column-xl, 4) - 16px);
    }
  }

  &.align-start {
    justify-content: flex-start;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-end {
    justify-content: flex-end;
  }
`
const StyledMobileBlock = styled.div`
  width: 100%;
  height: 100%;
`

export type CardListResponsiveOptions = {
  grid: {
    default?: number
    lg?: number
    xl?: number
  }
}
type CardListBlock<T> = {
  loading?: boolean
  className?: string
  dataSource: T[]
  responsiveOptions?: CardListResponsiveOptions
  listAlignment?: 'alignLeft' | 'alignCenter' | 'alignRight'
  render?: (item: T, index: number) => React.ReactNode
}
function CardListBlock<T>({
  loading,
  className,
  responsiveOptions,
  listAlignment = 'alignLeft',
  dataSource,
  render,
}: CardListBlock<T>) {
  const SpacedCarousel = dynamic(() => import('src/components/common/carousel/SpacedCarousel'), {
    ssr: false,
    loading: () => <div className="py-2 w-100">{dataSource[0] && render?.(dataSource[0], 0)}</div>,
  }) as React.FC<SpacedCarouselProps<T>>

  const alignClassName =
    listAlignment === 'alignLeft' ? 'align-start' : listAlignment === 'alignCenter' ? 'align-center' : 'align-end'

  return (
    <div className={className}>
      <Responsive.Media greaterThanOrEqual="lg">
        <div className="container">
          <Skeleton loading={loading}>
            <StyledDesktopBlock
              className={alignClassName}
              style={{
                ['--grid-column' as any]: responsiveOptions?.grid?.default,
                ['--grid-column-lg' as any]: responsiveOptions?.grid?.lg,
                ['--grid-column-xl' as any]: responsiveOptions?.grid?.xl,
              }}
            >
              {dataSource.map((item, index) => (
                <div key={index} className="item">
                  {render?.(item, index)}
                </div>
              ))}
            </StyledDesktopBlock>
          </Skeleton>
        </div>
      </Responsive.Media>

      <Responsive.Media lessThan="lg">
        <Skeleton loading={loading}>
          <SpacedCarousel
            dataSource={dataSource}
            render={(item, index) => (
              <StyledMobileBlock key={index} className="py-2 px-2 px-sm-0">
                {render?.(item, index)}
              </StyledMobileBlock>
            )}
            showArrow={false}
            showDots
            dotsClassName="mt-3"
            slidesPerViewResponsive={{
              tablet: 2.7,
            }}
          />
        </Skeleton>
      </Responsive.Media>
    </div>
  )
}

export default memo(
  CardListBlock,
  (prev, next) =>
    JSON.stringify(prev.dataSource) === JSON.stringify(next.dataSource) &&
    prev.loading === next.loading &&
    prev.className === next.className &&
    prev.listAlignment === next.listAlignment,
) as typeof CardListBlock
