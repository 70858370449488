'use client'

import { getTextColorByBackground } from '@havppen/utils/src/color'
import Link from 'next/link'
import React from 'react'
import styled, { css } from 'styled-components'

type CategoryTagSizeProps = {
  size?: 'small' | 'large' | 'default'
  shape?: 'round'
}

const StyledCategoryTagDiv = styled.div<CategoryTagSizeProps>`
  background-color: var(--theme-primary-color, black);
  display: inline-block;
  font-weight: 500;
  color: ${props => getTextColorByBackground(props.theme['primary-color'])};
  font-size: ${props => (props.size === 'small' ? '75%' : '.9rem')};
  padding: ${props => (props.size === 'small' ? '2px 8px' : props.size === 'large' ? '12px 16px' : '12px 4px')};

  ${props =>
    props.shape === 'round' &&
    css`
      border-radius: var(--default-border-radius, 16px);
    `}
`

const CategoryTag: React.FC<
  React.PropsWithChildren<{ href?: string | null; prefetch?: boolean; className?: string } & CategoryTagSizeProps>
> = ({ href, prefetch, className, size = 'small', shape, children }) => {
  return (
    <Link href={href || '/'} prefetch={prefetch} className={className}>
      <StyledCategoryTagDiv size={size} shape={shape}>
        {children}
      </StyledCategoryTagDiv>
    </Link>
  )
}

export default CategoryTag
