'use client'

import React, { useMemo } from 'react'
import { ActivityBriefProps } from 'src/hooks/activity.client'
import ProductCard, { ProductCardContent } from '../common/ProductCard'

const ActivityCard: React.FC<{
  activity: ActivityBriefProps
  showFavorite?: boolean
  openInNewTab?: boolean
  priority?: boolean
  isVisible?: boolean
}> = ({ activity, showFavorite, openInNewTab, priority, isVisible }) => {
  const upcomingSession = useMemo(() => {
    return activity.sessions.find(session => session.startAt > new Date()) || activity.sessions[0]
  }, [activity.sessions])

  return (
    <ProductCard
      productType="Activity"
      productTarget={activity.id}
      productStatus={activity.status}
      featuredImageUrl={activity.featuredImageUrl}
      title={activity.title}
      path={activity.path}
      members={activity.instructors}
      startAt={upcomingSession?.startAt}
      endAt={upcomingSession?.endAt}
      startSellAt={upcomingSession?.sellStartAt}
      openInNewTab={openInNewTab}
      showFavorite={showFavorite}
      priority={priority}
      isAuthorInfoHidden={activity.isAuthorInfoHidden}
      isVisible={isVisible}
    >
      <ProductCardContent
        listPrice={activity.listPrice}
        averageRating={activity.averageRating}
        reviewCount={activity.reviewCount}
        purchaseCount={activity.participantCount}
        isPriceHidden={activity.isPriceHidden}
        isParticipantCountHidden={activity.isParticipantCountHidden}
        isDiscountCountdownVisible={activity.isDiscountCountdownVisible}
      />
    </ProductCard>
  )
}

export default ActivityCard
