'use client'

import { CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { defineMessages } from '@formatjs/intl'
import { Button, Segmented } from 'antd'
import dynamic from 'next/dynamic'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ActivityCard from 'src/components/activity/ActivityCard'
import { CardListResponsiveOptions } from 'src/components/blockLayout/CardListBlock'
import PageSection from 'src/components/blockLayout/PageSection'
import { ActivityBriefProps } from 'src/hooks/activity.client'
import HomeCardListBlock from 'src/views/HomePage/default/HomeCardListBlock'
const ProductEventCalendar = dynamic(() => import('src/components/common/ProductEventCalendar'), { ssr: false })

const intlMessages = defineMessages({
  allActivities: {
    id: 'components.ActivityListSection.title.allActivities',
    defaultMessage: '所有活動',
  },
  showMore: {
    id: 'components.ActivityListSection.label.showMore',
    defaultMessage: '顯示更多活動',
  },
})

export type ActivityListSectionProps = {
  loadingActivities?: boolean
  activities?: ActivityBriefProps[]
  categoryId?: string | null
  responsiveOptions?: CardListResponsiveOptions
  title?: string
  buttonText?: string
  buttonPosition?: 'left' | 'right'
  buttonSize?: 'small' | 'middle' | 'large'
  listAlignment?: 'alignLeft' | 'alignCenter' | 'alignRight'
}
const ActivityListSection: React.FC<ActivityListSectionProps> = ({
  loadingActivities,
  activities = [],
  responsiveOptions,
  title,
  buttonText,
  buttonPosition = 'right',
  buttonSize = 'middle',
  listAlignment = 'alignLeft',
}) => {
  const { formatMessage } = useIntl()
  const [calendarDisplayType, setCalendarDisplayType] = useState('card')
  if (!loadingActivities && activities.length === 0) {
    return null
  }

  return (
    <PageSection
      loading={loadingActivities}
      center
      title={title || formatMessage(intlMessages.allActivities)}
      extraPosition={buttonPosition}
      extra={
        <Segmented
          value={calendarDisplayType}
          onChange={value => setCalendarDisplayType(value.toString())}
          options={[
            { label: '卡片', icon: <UnorderedListOutlined />, value: 'card' },
            { label: '日曆', icon: <CalendarOutlined />, value: 'calendar' },
          ]}
        />
      }
    >
      {calendarDisplayType === 'card' ? (
        <HomeCardListBlock
          className="mt-3 mt-sm-4"
          responsiveOptions={responsiveOptions}
          listAlignment={listAlignment}
          loading={loadingActivities}
          dataSource={activities}
          render={(activity, index) => <ActivityCard activity={activity} priority={index === 0} />}
        />
      ) : (
        <div className="container">
          <ProductEventCalendar
            productEvents={activities.flatMap(activity =>
              activity.sessions.map(session => ({
                id: activity.id,
                title: activity.title,
                featuredImageUrl: activity.featuredImageUrl,
                path: activity.path,
                startAt: session.startAt,
                endAt: session.endAt,
              })),
            )}
          />
        </div>
      )}

      <div className="text-center mt-4">
        <Button href="/activities" shape="round" size={buttonSize}>
          <div className="px-3">
            <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
            <span className="ml-1">»</span>
          </div>
        </Button>
      </div>
    </PageSection>
  )
}

export default ActivityListSection
