'use client'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import clsx from 'clsx'
import { KeenSliderInstance, KeenSliderOptions } from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'
import React, { MutableRefObject, useContext, useEffect, useMemo, useState } from 'react'
import SlideImage from 'src/components/carousel/SlideImage'
import { ResponsiveContext } from 'src/contexts/ResponsiveContext'
import styled from 'styled-components'
import { autoSwitchPlugin, thumbnailPlugin } from '.'

type StyledCarouselContainerDivProps = {
  $slidesPerViewResponsive?: {
    default?: number
    tablet?: number
    desktop?: number
    largeDesktop?: number
  }
}
const StyledCarouselContainerDiv = styled.div<StyledCarouselContainerDivProps>`
  height: 100%;

  .dots {
    position: relative;
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }
  .dot {
    border: none;
    width: 12px;
    height: 4px;
    background: var(--theme-text-color);
    border-radius: 2px;
    margin: 0 3px;
    cursor: pointer;
  }
  .dot:focus {
    outline: none;
  }
  .dot.active {
    width: 30px;
    background: var(--theme-primary-color);
  }

  .slides-wrapper {
    position: relative;

    &.fader {
      position: relative;
      width: 100%;

      height: 0;
      padding-bottom: calc(100% * 600 / 1366);

      @supports (aspect-ratio: 1 / 1) {
        height: auto;
        aspect-ratio: 1366 / 600;
        padding-bottom: 0;
      }
    }
    .fader__slide {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .arrow {
      position: absolute;
      top: 50%;
      z-index: 99;
      transform: translateY(-50%);
      &.left-arrow {
        left: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.default || 1.5} - 10px);
        @media screen and (min-width: 768px) {
          left: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.tablet || 2} - 10px);
        }
        @media screen and (min-width: 1200px) {
          left: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.desktop || 2} - 10px);
        }
        @media screen and (min-width: 1900px) {
          left: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.largeDesktop || 2.6} - 10px);
        }
      }
      &.right-arrow {
        right: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.default || 1.5} - 10px);
        @media screen and (min-width: 768px) {
          right: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.tablet || 2} - 10px);
        }
        @media screen and (min-width: 1200px) {
          right: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.desktop || 2} - 10px);
        }
        @media screen and (min-width: 1900px) {
          right: calc(50% - 50% / ${props => props.$slidesPerViewResponsive?.largeDesktop || 2.6} - 10px);
        }
      }
    }
  }
`

export type SpacedCarouselProps<T> = {
  dataSource: T[]
  render: (item: T, index: number) => React.ReactNode
  showArrow?: boolean
  showDots?: boolean
  showThumb?: boolean
  thumbsPerView?: number
  thumbImageUrls?: string[]
  arrowButtonShape?: 'circle' | 'round'
  dotsClassName?: string
  slidesPerViewResponsive?: {
    default?: number
    tablet?: number
    desktop?: number
    largeDesktop?: number
  }
  switchMode?: 'swipe' | 'fade'
  arrowButtonProps?: ButtonProps
  renderCustomLeftArrowButton?: React.FC<{ onClick: () => void }>
  renderCustomRightArrowButton?: React.FC<{ onClick: () => void }>
  autoSwitchSlideInterval?: number
  overrideSlideWrapperClassName?: string
  overrideSlideClassName?: string
  onReady?: (slider: KeenSliderInstance) => void
} & KeenSliderOptions

function SpacedCarousel<T>({
  dataSource,
  render,
  showArrow = true,
  showDots = true,
  showThumb = false,
  thumbsPerView = 6,
  thumbImageUrls,
  switchMode = 'swipe',
  arrowButtonShape,
  dotsClassName,
  renderCustomLeftArrowButton,
  renderCustomRightArrowButton,
  slidesPerViewResponsive,
  autoSwitchSlideInterval,
  arrowButtonProps,
  onReady,
  ...rest
}: SpacedCarouselProps<T>) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [opacities, setOpacities] = useState<number[]>([])

  const [sliderRef, slider] = useKeenSlider(
    {
      loop: dataSource.length > 1,
      mode: 'snap',
      renderMode: 'performance',
      slides:
        switchMode === 'fade'
          ? dataSource.length
          : {
              spacing: 20,
              origin: 'center',
              perView: slidesPerViewResponsive?.default || 1.4,
            },
      breakpoints: {
        '(min-width: 768px)': {
          slides: {
            spacing: 20,
            origin: 'center',
            perView: slidesPerViewResponsive?.tablet || 2,
          },
        },
        '(min-width: 1200px)': {
          slides: {
            spacing: 40,
            origin: 'center',
            perView: slidesPerViewResponsive?.desktop || 2,
          },
        },
        '(min-width: 1600px)': {
          slides: {
            spacing: 40,
            origin: 'center',
            perView: slidesPerViewResponsive?.largeDesktop || 2.6,
          },
        },
      },
      initial: 0,
      slideChanged(slider) {
        const slideIndex = slider.track.details.rel
        if (typeof slideIndex === 'number' && !isNaN(slideIndex)) {
          setCurrentSlideIndex(slideIndex)
        }
      },
      detailsChanged(slider) {
        if (switchMode === 'fade') {
          const opacities = slider.track.details.slides.map(slide => slide.portion)
          setOpacities(opacities)
        }
      },
      ...rest,
    },
    [autoSwitchPlugin(autoSwitchSlideInterval)],
  )

  useEffect(() => {
    if (slider.current) {
      onReady?.(slider.current)
    }
  }, [slider, onReady])

  return (
    <StyledCarouselContainerDiv $slidesPerViewResponsive={slidesPerViewResponsive}>
      <div ref={sliderRef} className={clsx('slides-wrapper', switchMode === 'fade' ? 'fader' : `keen-slider`)}>
        {dataSource.map((slide, index) => (
          <div
            key={index}
            className={clsx(switchMode === 'fade' ? 'fader__slide' : `keen-slider__slide`)}
            style={{
              opacity: switchMode === 'fade' ? opacities[index] : 1,
              pointerEvents: switchMode === 'fade' ? (opacities[index] === 1 ? 'auto' : 'none') : undefined,
            }}
          >
            {render(slide, index)}
          </div>
        ))}

        {showArrow && slider && (
          <>
            <div className="arrow left-arrow">
              {renderCustomLeftArrowButton ? (
                renderCustomLeftArrowButton({ onClick: () => slider.current?.prev() })
              ) : (
                <Button
                  shape={arrowButtonShape}
                  icon={<LeftOutlined />}
                  onClick={() => slider.current?.prev()}
                  {...arrowButtonProps}
                />
              )}
            </div>
            <div className="arrow right-arrow">
              {renderCustomRightArrowButton ? (
                renderCustomRightArrowButton({ onClick: () => slider.current?.next() })
              ) : (
                <Button
                  shape={arrowButtonShape}
                  icon={<RightOutlined />}
                  onClick={() => slider.current?.next()}
                  {...arrowButtonProps}
                />
              )}
            </div>
          </>
        )}
      </div>

      {showThumb && slider && thumbImageUrls && (
        <ThumbCarousel sliderRef={slider} thumbImageUrls={thumbImageUrls} thumbsPerView={thumbsPerView} />
      )}

      {showDots && slider && dataSource.length > 1 && (
        <div className={clsx('dots', dotsClassName)}>
          {dataSource.map((slide, idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  slider.current?.moveToIdx(idx)
                }}
                aria-label={idx.toString()}
                className={clsx('dot', currentSlideIndex === idx && 'active', idx)}
              />
            )
          })}
        </div>
      )}
    </StyledCarouselContainerDiv>
  )
}

type ThumbCarouselProps = {
  sliderRef: MutableRefObject<KeenSliderInstance | null>
  thumbImageUrls: string[]
  thumbsPerView?: number
}
const maxThumbPerView = 8
const maxThumbPerViewMobile = 3
function ThumbCarousel({ sliderRef, thumbsPerView = 4, thumbImageUrls }: ThumbCarouselProps) {
  const { isMobile } = useContext(ResponsiveContext)
  const slidesPerView = useMemo(() => {
    if (isMobile) return Math.min(thumbImageUrls.length, maxThumbPerViewMobile)

    return Math.max(Math.min(thumbImageUrls.length, maxThumbPerView), Math.max(thumbImageUrls.length, thumbsPerView))
  }, [isMobile, thumbImageUrls.length, thumbsPerView])

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: slidesPerView,
        spacing: 10,
      },
      slideChanged(slider) {
        const slideIndex = slider.track.details.rel
        if (typeof slideIndex === 'number' && !isNaN(slideIndex)) {
          setCurrentSlideIndex(slideIndex)
        }
      },
    },
    [thumbnailPlugin(sliderRef)],
  )

  return (
    <div ref={thumbnailRef} className="keen-slider thumbnail-sliders-wrapper">
      {thumbImageUrls.map((thumbImageUrl, key) => (
        <div
          key={key}
          className={clsx('keen-slider__slide', {
            active: currentSlideIndex === key,
          })}
        >
          <SlideImage imageUrl={thumbImageUrl || ''} title="" />
        </div>
      ))}
    </div>
  )
}

export default SpacedCarousel
