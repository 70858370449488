/* eslint-disable @next/next/no-img-element */
import { CloseOutlined, DownloadOutlined, ToTopOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import React, { useState } from 'react'
import UploadImageModal from './UploadImageModal'

const UploadImage: React.FC<{
  path: string
  value?: string | null
  width?: number
  aspectRatio?: number
  onChange?: (fileUrl: string | null) => void
  onUploading?: () => void
  onSuccess?: (filepath: string, file: File) => void
  onError?: (err: any) => void
  onImageSize?: (width: number, height: number) => void
}> = ({ value, path, width, aspectRatio, onChange, onUploading, onSuccess, onError, onImageSize }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <div>
      <UploadImageModal
        open={isModalVisible}
        path={path}
        aspectRatio={aspectRatio}
        onChange={onChange}
        onOpenChange={setIsModalVisible}
        onUploading={() => {
          setLoading(true)
          onUploading?.()
        }}
        onError={error => {
          setLoading(false)
          onError?.(error)
        }}
        onSuccess={(filepath, file) => {
          setLoading(false)
          onSuccess?.(filepath, file)
        }}
        onFileChange={file => {
          if (file) {
            const reader = new FileReader()
            reader.onload = e => {
              const img = new Image()
              img.onload = () => {
                onImageSize?.(img.width, img.height)
              }
              img.src = e.target?.result as string
            }
            reader.readAsDataURL(file)
          }
        }}
      />

      {value ? (
        <>
          <img
            src={value}
            width={width ?? '100%'}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => setIsModalVisible(true)}
          />

          <Space style={{ position: 'absolute', bottom: 10, right: 10 }}>
            <Button shape="round" icon={<DownloadOutlined />} loading={loading} onClick={() => window.open(value)}>
              下載
            </Button>
            <Button
              shape="circle"
              icon={<ToTopOutlined />}
              loading={loading}
              onClick={() => setIsModalVisible(true)}
            ></Button>
            <Button shape="circle" icon={<CloseOutlined />} loading={loading} onClick={() => onChange?.(null)}></Button>
          </Space>
        </>
      ) : (
        <Button type="primary" icon={<ToTopOutlined />} loading={loading} onClick={() => setIsModalVisible(true)}>
          {value ? '重新上傳' : '上傳圖片'}
        </Button>
      )}
    </div>
  )
}

export default UploadImage
