'use client'

import { KeenSliderOptions } from 'keen-slider'
import dynamic from 'next/dynamic'
import React, { memo, useEffect, useMemo } from 'react'
import PlaceholderImage from 'src/assets/images/image-placeholder.jpg'
import { pascalCase } from 'src/helpers'
import { useCarousel } from 'src/hooks/carousel.client'
import { CarouselBlock, CarouselProps, CarouselSlideProps, CarouselType } from 'src/types/carousel'
import styled, { css } from 'styled-components'

export type CarouselSectionProps = {
  loading: boolean
  className?: string
  priority?: boolean
  slides: CarouselSlideProps[]
  carouselOptions?: KeenSliderOptions
}

export const StyledLoadingCarouselDiv = styled.div<{ type?: CarouselType; $isPlaceHolder?: boolean }>`
  position: relative;
  width: 100%;
  animation: skeleton-loading 1s linear infinite alternate;
  background-image: ${props => (props.$isPlaceHolder ? `url(${PlaceholderImage.src})` : 'none')};
  background-size: cover;

  height: 0;
  ${props =>
    props.type === 'image_with_text_button_vertical'
      ? css`
          --image-width: var(--default-width, 1366);
          --image-height: var(--default-height, 600);
        `
      : props.type === 'image_only'
      ? css`
          --image-width: var(--default-width, 1700);
          --image-height: var(--default-height, 840);
        `
      : props.type === 'image_with_text_button'
      ? css`
          --image-width: var(--default-width, 2200);
          --image-height: var(--default-height, 800);
        `
      : css`
          --image-width: var(--default-width, 1366);
          --image-height: var(--default-height, 600);
        `};

  padding-bottom: calc(100% * var(--image-height) / var(--image-width));

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
`

const CarouselSection: React.FC<{
  priority?: boolean
  className?: string
  type?: CarouselType
  block: CarouselBlock
  onCarouselFetch?: (carousel: CarouselProps) => void
  onCarouselLoaded?: () => void
}> = ({ type, block, priority, className, onCarouselFetch, onCarouselLoaded }) => {
  const { loadingCarousel, carousel } = useCarousel({ type, block })
  useEffect(() => {
    if (carousel) {
      onCarouselFetch?.(carousel)
    }
  }, [carousel, onCarouselFetch])

  useEffect(() => {
    if (!loadingCarousel) {
      onCarouselLoaded?.()
    }
  }, [loadingCarousel, onCarouselLoaded])

  const carouselProps: CarouselSectionProps = useMemo(
    () => ({
      loading: loadingCarousel,
      className,
      slides: carousel?.slides ?? [],
      priority,
      carouselOptions: {
        showDots: !carousel?.metadata.isPaginationDisabled,
        showArrow: !carousel?.metadata.isNavigationDisabled,
      },
    }),
    [carousel, className, loadingCarousel, priority],
  )

  const Carousel = useMemo(() => {
    const carouselType = carousel ? pascalCase(carousel.type) : 'Default'
    return dynamic<CarouselSectionProps>(
      () => {
        switch (carouselType) {
          case 'ImageOnly':
            return import('./ImageOnlyCarousel')
          case 'ImageWithTextButton':
            return import('./ImageWithTextButtonCarousel')
          case 'ImageWithTextButtonVertical':
            return import('./ImageWithTextButtonVerticalCarousel')
          case 'ImageWithThumbnailList':
            return import('./ImageWithThumbnailListCarousel')
          default:
            return import('./DefaultCarousel')
        }
      },
      { ssr: false, loading: () => <StyledLoadingCarouselDiv className={className} type={carousel?.type ?? type} /> },
    )
  }, [className, type, carousel])
  return <Carousel {...carouselProps} />
}

export default memo(
  CarouselSection,
  (prev, next) =>
    prev.block === next.block &&
    prev.className === next.className &&
    prev.priority === next.priority &&
    prev.type === next.type &&
    prev.onCarouselFetch === next.onCarouselFetch &&
    prev.onCarouselLoaded === next.onCarouselLoaded,
)
