'use client'

import { toolize } from '../..'
import ImageSection, { ImageSectionProps } from './ImageSection'

const ImageSectionToolize: React.FC<ImageSectionProps> = props => {
  return <ImageSection {...props} />
}

export default toolize(ImageSectionToolize)
