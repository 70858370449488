'use client'

import { defineMessages } from '@formatjs/intl'
import { Button } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import CardListBlock from 'src/components/blockLayout/CardListBlock'
import PageSection from 'src/components/blockLayout/PageSection'
import MerchandiseCard from 'src/components/merchandise/MerchandiseCard'
import { MerchandiseBriefProps } from 'src/types/merchandise'
import { ListSectionProps } from '../ListSection/ListSection'

export type MerchandiseListSectionProps = ListSectionProps & {
  categoryId?: string | null
  loadingMerchandises?: boolean
  merchandises?: MerchandiseBriefProps[]
}

const intlMessages = defineMessages({
  allMerchandises: {
    id: 'components.MerchandiseListSection.title.allMerchandises',
    defaultMessage: '所有商品',
  },
  showMore: {
    id: 'components.MerchandiseListSection.label.showMore',
    defaultMessage: '顯示更多商品',
  },
})

const MerchandiseListSection: React.FC<MerchandiseListSectionProps> = ({
  loadingMerchandises,
  merchandises = [],
  responsiveOptions,
  title,
  buttonText,
  buttonPosition = 'right',
  buttonSize = 'middle',
  listAlignment = 'alignLeft',
}) => {
  const { formatMessage } = useIntl()
  if (!loadingMerchandises && merchandises.length === 0) {
    return null
  }

  return (
    <PageSection
      loading={loadingMerchandises}
      center
      title={title || formatMessage(intlMessages.allMerchandises)}
      extra={
        buttonPosition !== 'bottom' ? (
          <Button href="/merchandises" shape="round" size={buttonSize}>
            <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
            <span className="ml-1">»</span>
          </Button>
        ) : undefined
      }
      extraPosition={buttonPosition === 'bottom' ? undefined : buttonPosition}
    >
      <CardListBlock
        className="mt-3 mt-sm-4"
        responsiveOptions={responsiveOptions}
        listAlignment={listAlignment}
        loading={loadingMerchandises}
        dataSource={merchandises}
        render={(merchandise, index) => <MerchandiseCard merchandise={merchandise} priority={index === 0} />}
      />

      {buttonPosition === 'bottom' && (
        <div className="text-center mt-4">
          <Button href="/merchandises" shape="round" size={buttonSize}>
            <div className="px-3">
              <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
              <span className="ml-1">»</span>
            </div>
          </Button>
        </div>
      )}
    </PageSection>
  )
}

export default MerchandiseListSection
