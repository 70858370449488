import { gql } from '@apollo/client'

export const GET_CAROUSEL = gql`
  query GET_CAROUSEL($condition: carousel_bool_exp!) {
    carousel(where: $condition, order_by: { position: asc }) {
      id
      name
      description
      default_target_url
      metadata
      type
      block
      carousel_slides(order_by: { position: asc }) {
        title
        content
        image_url
        image_alt
        target_url
        position
        metadata
      }
    }
  }
`
