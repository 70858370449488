import { useEditor, useNode, UserComponent } from '@craftjs/core'
import clsx from 'clsx'
import React, { ElementType } from 'react'
import { CSSProp, styled } from 'styled-components'
import ElementControls from './ElementControls'

export type ElementSettingsProps<T = any> = ToolizeElementProps<T>
export type ElementSettings<T = any> = ElementType<{
  props: ElementSettingsProps<T>
  onPropsChange?: (props: ElementSettingsProps<T>) => void
}>

export type ElementPropsWithState<T> = { isEditing?: boolean } & (
  | ({ loading: true; errors?: never } & Partial<T>)
  | ({ loading?: never; errors?: Error[] } & Partial<T>)
  | ({ loading?: never; errors?: never } & T)
)
export type ToolizeElementProps<T> = React.PropsWithChildren<ElementPropsWithState<T & { customStyle?: CSSProp }>>
export type ToolizeElement<T> = React.ComponentType<ToolizeElementProps<T>>

const StyledToolizedElementDiv = styled.div<{ customStyle?: CSSProp }>`
  position: relative;

  .editing {
    &.component-selected {
      outline: 2px dashed #37a7ff;
    }
  }
  .component-selected:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${props => props.customStyle}
`
export function toolize<P>(Component: ToolizeElement<P>) {
  const StyledToolizedElement: ToolizeElement<P> = styled(Component)(
    (props: ToolizeElementProps<P>) => props.customStyle || {},
  )

  const ToolizedComponent: UserComponent<ToolizeElementProps<P>> = props => {
    const { isHovered, isSelected, connectors, isInlineEditable } = useNode(node => {
      return {
        isHovered: node.events.hovered,
        isSelected: node.events.selected,
        isInlineEditable: node.data.custom.isInlineEditable ?? false,
      }
    })
    const { isEditing } = useEditor(state => ({
      isEditing: state.options.enabled,
    }))

    return (
      <StyledToolizedElementDiv
        customStyle={props.customStyle}
        className={clsx({
          'component-selected': (isHovered || isSelected) && !isInlineEditable,
          editing: isEditing,
        })}
        ref={ref => {
          if (ref) {
            connectors.connect(ref)
          }
        }}
      >
        <StyledToolizedElement
          {...(props as ToolizeElementProps<P>)}
          isEditing={isEditing}
          customStyle={props.customStyle}
        />
        <ElementControls />
      </StyledToolizedElementDiv>
    )
  }
  return ToolizedComponent
}
