import types from '@havppen/gql/types'
import { CarouselBlock, CarouselProps, CarouselType } from 'src/types/carousel'

export type CarouselQueryOptions = {
  carouselId?: string
  type?: CarouselType
  block?: CarouselBlock
  skip?: boolean
}

export const mapCarouselQueryOptionsToGqlVariables = (options: CarouselQueryOptions, params?: { appId: string }) => {
  const condition: types.carousel_bool_exp = {
    id: options.carouselId ? { _eq: options.carouselId } : undefined,
    type: options.type ? { _eq: options.type } : undefined,
    block: options.block ? { _eq: options.block } : undefined,
    status: { _eq: 'published' },
    app_id: params?.appId ? { _eq: params.appId } : undefined,
  }
  const variables: types.GET_CAROUSELVariables = {
    condition,
  }

  return variables
}

export const formatGqlCarousel = (data: types.GET_CAROUSEL | undefined) => {
  const carousels: CarouselProps[] = (data?.carousel || []).map(carousel => ({
    id: carousel.id,
    name: carousel.name,
    description: carousel.description,
    type: carousel.type as CarouselType,
    block: carousel.block as CarouselBlock,
    metadata: carousel.metadata,
    slides: carousel.carousel_slides.map(carousel_slide => ({
      title: carousel_slide.title,
      content: carousel_slide.content,
      imageUrl: carousel_slide.image_url || carousel.default_target_url,
      imageAlt: carousel_slide.image_alt,
      targetUrl: carousel_slide.target_url,
      position: carousel_slide.position,
      metadata: carousel_slide.metadata,
    })),
  }))

  return carousels.length > 0
    ? {
        ...carousels[0],
        slides: carousels.flatMap(carousel => carousel.slides),
      }
    : null
}
