'use client'

import React from 'react'
import styled, { css } from 'styled-components'
import LazyLoadImage from './LazyLoadImage'

const StyledCoverContainer = styled.div<{ isVideo?: boolean }>`
  position: relative;
  background-color: #e2e2e2;
  top: 0;
  left: 0;
  overflow: hidden;

  width: 100%;
  padding-bottom: 56%;

  ${props =>
    props.isVideo &&
    css`
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: block;
        background: #000;
        opacity: 0;
        transition: all 0.3s;
        z-index: 19;
      }
      &:hover:before {
        opacity: 0.65;
      }

      &:after {
        content: '';
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        background-image: url(/icon_play.svg);
        background-position: 50%;
        background-size: contain;
      }
      &:hover:after {
        width: 60px;
        height: 60px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 29;
      }
    `}
`

const CoverImage: React.FC<{
  imageUrl?: string | null
  isVideo?: boolean
  preload?: boolean
  alt?: string
  className?: string
  sizes?: string
}> = ({ imageUrl, preload, sizes, alt, ...props }) => {
  return (
    <StyledCoverContainer {...props}>
      <LazyLoadImage
        src={imageUrl || 'https://picsum.photos/2000/800'}
        fill
        sizes={sizes}
        priority={preload}
        alt={alt ?? ''}
      />
    </StyledCoverContainer>
  )
}

export default CoverImage
