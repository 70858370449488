'use client'

import { Skeleton, Space } from 'antd'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { BREAK_POINT } from 'src/contexts/ResponsiveContext'
import { styled } from 'styled-components'

type PageSectionProps = {
  loading?: boolean
  className?: string
  title?: React.ReactNode
  subtitle?: React.ReactNode
  center?: boolean
  extra?: React.ReactNode
  extraPosition?: 'left' | 'right'
}

const StyledPageSectionDiv = styled.div`
  padding: 20px 0;
  @media screen and (min-width: ${BREAK_POINT}px) {
    padding: 60px 0;
  }

  .ant-skeleton-title {
    margin-bottom: 0px;
  }
  ul.ant-skeleton-paragraph {
    margin-top: 16px !important;
    margin-bottom: 0px;
  }
`
const StyledTitleBlockDiv = styled.div`
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .description {
    font-size: 14px;
  }
  @media screen and (min-width: ${BREAK_POINT}px) {
    .title {
      font-size: 28px;
    }
    .description {
      font-size: 16px;
    }
  }
`

const PageSection: React.FC<React.PropsWithChildren<PageSectionProps>> = ({
  loading,
  children,
  title = null,
  subtitle = null,
  extra = null,
  extraPosition = 'right',
  center = false,
  className,
  ...rest
}) => {
  const justifyClassName = useMemo(
    () => (center ? 'justify-content-around' : extra ? 'justify-content-between' : ''),
    [center, extra],
  )

  return (
    <StyledPageSectionDiv className={className} {...rest}>
      <div className="container">
        <Skeleton loading={loading} paragraph={{ rows: 1, width: '100%' }}>
          <div className={clsx('d-flex top align-items-center mb-6', justifyClassName)}>
            {extra && extraPosition === 'left' && (
              <div>
                <Space>{extra}</Space>
              </div>
            )}

            <StyledTitleBlockDiv className={center ? 'text-center' : 'px-4'}>
              <h2 className="title mb-1">{title}</h2>
              <div className="description">{subtitle}</div>
            </StyledTitleBlockDiv>

            {extra && extraPosition === 'right' && (
              <div>
                <Space>{extra}</Space>
              </div>
            )}
          </div>
        </Skeleton>
      </div>

      {children}
    </StyledPageSectionDiv>
  )
}

export default PageSection
