'use client'

import { Button } from 'antd'
import dayjs from 'dayjs'
import Link from 'next/link'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import CategoryTag from 'src/components/common/CategoryTag'
import CoverImage from 'src/components/common/CoverImage'
import TagListBox from 'src/components/common/TagListBox'
import { BREAK_POINT, BREAK_POINT_MD, BREAK_POINT_XL } from 'src/contexts/ResponsiveContext'
import { ArticleBasicProps } from 'src/types/article'
import styled from 'styled-components'
import FavoriteButton from '../common/FavoriteButton'

const StyledRankingNotation = styled.div`
  font-weight: 900;
  font-style: italic;
  font-size: 2rem;
  @media screen and (min-width: ${BREAK_POINT_MD}px) {
    font-size: 1.4rem;
  }
`
const StyledRow = styled.div`
  text-align: left;
  height: 100%;

  .row-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;

    @media screen and (min-width: ${BREAK_POINT_MD}px) {
      font-size: 16px;
    }
    @media screen and (min-width: ${BREAK_POINT_XL}px) {
      font-size: 20px;
    }
    &,
    a {
      color: var(--theme-title-color);
      transition: 0.5s color;
      &:hover {
        color: var(--theme-title-hover-color);
      }
    }
    &.small {
      font-size: 0.8rem;
      @media screen and (min-width: ${BREAK_POINT_MD}px) {
        font-weight: 400;
        font-size: 0.85rem;
      }
      @media screen and (min-width: ${BREAK_POINT}px) {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
  .row-caption {
    &,
    a {
      color: var(--theme-text-color);
    }
    a:hover {
      color: var(--theme-primary-color);
    }
  }
  .abstract {
    color: var(--theme-text-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-height: 44px;
    @media screen and (min-width: ${BREAK_POINT}px) {
      max-height: auto;
    }
    @media screen and (min-width: ${BREAK_POINT_XL}px) {
      font-size: 14px;
      line-height: 1.5;
    }
    &,
    * {
      font-size: 12px !important;
    }
  }
  .favorite {
    top: 10px;
    left: 24px;

    position: absolute;
  }
`

const ArticleRow: React.FC<{
  article: ArticleBasicProps
  rankingNo?: number
  datetimeFormat?: string
  noCategory?: boolean
  noTags?: boolean
  noCaption?: boolean
  preloadImage?: boolean
  showAbstract?: boolean
  showContinueReadingButton?: boolean
  showFavoriteButton?: boolean
  size?: 'default' | 'small'
  imageSizes?: string
  prefetch?: boolean
  isVisible?: boolean
}> = ({
  article,
  rankingNo,
  datetimeFormat,
  noCategory,
  noTags,
  noCaption,
  showAbstract,
  showContinueReadingButton,
  showFavoriteButton,
  preloadImage,
  size,
  imageSizes,
  prefetch,
  isVisible,
}) => {
  const articleCategory = article.categories?.[0]
  const articleAuthor = article.author
  const isAuthorHidden = article.author?.isPrivate ?? false
  const articleAuthorPath = `/author/${encodeURIComponent(articleAuthor?.displayName || '')}`
  const articleHref = useMemo(() => `/article/${article.slug || article.id}`, [article.id, article.slug])

  const textBoxClassnames = useMemo(() => {
    let textBoxClassnames = 'text-box py-lg-0 d-flex flex-column align-self-center '
    if (rankingNo) {
      textBoxClassnames += 'pl-0 col-lg-6 '
      if (size === 'small') {
        textBoxClassnames += 'py-0 col-6 '
      } else {
        textBoxClassnames += 'pt-3 '
      }
    } else {
      textBoxClassnames += 'col-lg-7 col-md-7 d-flex flex-column align-self-center '
      if (size === 'small') {
        textBoxClassnames += 'py-0 col-7 '
      } else {
        textBoxClassnames += 'pt-3 '
      }
    }
    return textBoxClassnames
  }, [rankingNo, size])

  const [isClientLoaded, setIsClientLoaded] = useState(false)
  useEffect(() => {
    setIsClientLoaded(true)
  }, [])

  return (
    <StyledRow
      className="row position-relative align-items-center flex-wrap"
      // TODO: uncomment this when Group limitation features is fully released
      // style={{ display: isVisible !== undefined ? (isVisible ? 'flex' : 'none') : 'flex' }}
    >
      {rankingNo && (
        <div className={`col-lg-1 d-flex flex-column align-self-center ${size === 'small' ? 'col-1' : ''}`}>
          <StyledRankingNotation>{rankingNo}</StyledRankingNotation>
        </div>
      )}

      <div className={`col-lg-5 col-md-5 col-12 ${size === 'small' ? 'col-5 align-self-center' : ''}`}>
        <Link href={articleHref} prefetch={prefetch}>
          <CoverImage
            className="cover-image"
            imageUrl={article.featuredImageUrl}
            preload={preloadImage}
            alt={article.title}
            sizes="(max-width: 768px) 260vw, 320px"
          />
        </Link>

        {showFavoriteButton && (
          <div className="favorite">
            <FavoriteButton productId={`Article_${article.id}`} />
          </div>
        )}
      </div>
      <div className={textBoxClassnames}>
        {!noCategory && articleCategory && (
          <div className={size === 'small' ? 'mb-1' : 'mb-2'}>
            <CategoryTag href={`/${articleCategory.slug}`} className="mb-lg-1" prefetch={prefetch}>
              {articleCategory.name}
            </CategoryTag>
          </div>
        )}
        {!noTags && article.tags.length > 0 && (
          <div className={`mb-1 ${size === 'small' ? 'mb-lg-1' : 'mb-lg-2 '}`}>
            <TagListBox tagNames={article.tags} />
          </div>
        )}

        <div>
          <h4 className={`row-title ${size === 'small' ? 'small' : ''}`}>
            <Link href={articleHref} prefetch={prefetch}>
              {article.title}
            </Link>
          </h4>

          {showAbstract && (
            <div className="mt-3 abstract" dangerouslySetInnerHTML={{ __html: article.abstract || '' }} />
          )}
        </div>

        {!noCaption && (
          <div
            className={`row-caption ${showAbstract ? 'mg-lg-2' : 'mt-lg-3'} mb-2 ${size === 'small' ? 'mt-0' : 'mt-3'}`}
          >
            {!isAuthorHidden && (
              <span>
                <span className="mr-1">by</span>

                <Link href={articleAuthorPath} prefetch={prefetch}>
                  {articleAuthor?.displayName}
                </Link>

                <span className="mx-1">-</span>
              </span>
            )}
            <span>
              {dayjs(article.onlineAt).format(datetimeFormat || (isClientLoaded ? 'DD MMM YYYY' : 'DD MM YYYY'))}
            </span>
          </div>
        )}

        {showContinueReadingButton && (
          <Button className="continue-reading-button" shape="round" href={articleHref}>
            <FormattedMessage id="page.button.readMore" />
          </Button>
        )}
      </div>
    </StyledRow>
  )
}

export default ArticleRow
