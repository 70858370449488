'use client'

import Image from 'next/image'

const SlideImage: React.FC<{ imageUrl: string; title: string | null; priority?: boolean }> = ({
  imageUrl,
  title,
  priority,
}) => (
  <div className="image-wrapper">
    <Image
      fill
      quality={100}
      priority={priority}
      src={imageUrl}
      alt={title || ''}
      sizes="(max-width: 768px) 828px, (max-width: 992px) 1080px, (max-width: 1200px) 1200px, 1400px"
      unoptimized={imageUrl?.endsWith('.svg') || imageUrl?.endsWith('.gif')}
    />
  </div>
)

export default SlideImage
