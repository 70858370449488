import UppyAwsS3, { AwsS3Options } from '@uppy/aws-s3'
import Uppy, { BasePlugin } from '@uppy/core'
import UppyImageEditor from '@uppy/image-editor'
import UppyZhTW from '@uppy/locales/lib/zh_TW'
import { DashboardModal } from '@uppy/react'
import ms from 'ms'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { useApp } from '../../../contexts/AppContext'
import { useAuth } from '../../../contexts/AuthContext'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'
import { getUploadFilePath, getUploadUrl } from 'src/utils/upload.client'

const UploadImageModal: React.FC<{
  open?: boolean
  path: string
  aspectRatio?: number
  onChange?: (fileUrl: string | null) => void
  onUploading?: () => void
  onSuccess?: (filepath: string, file: File) => void
  onError?: (err: any) => void
  onOpenChange?: (open: boolean) => void
  onFileChange?: (file: File | null) => void
}> = ({ open = false, aspectRatio, path, onChange, onUploading, onSuccess, onError, onOpenChange, onFileChange }) => {
  const { id: appId } = useApp()
  const { authToken } = useAuth()
  const [fileUrl, setFileUrl] = useState<string | null>(null)

  const [uppy] = useState(() => {
    return new Uppy({
      locale: UppyZhTW,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
    })
      .use(UppyImageEditor, { id: 'ImageEditor' })
      .use(UppyAwsS3, { id: 'AwsS3' })
  })

  useEffect(() => {
    uppy.on('upload', () => {
      onUploading?.()
    })
  }, [onUploading, uppy])

  useEffect(() => {
    uppy.on('file-added', event => {
      const file = event.data as File
      onFileChange?.(file)
    })
  }, [onFileChange, uppy])

  useEffect(() => {
    uppy.on('file-removed', () => {
      onFileChange?.(null)
    })
  }, [onFileChange, uppy])

  useEffect(() => {
    const plugin = uppy.getPlugin('ImageEditor')
    plugin?.setOptions({
      cropperOptions: {
        initialAspectRatio: aspectRatio,
        aspectRatio,
        viewMode: 1,
        background: false,
        autoCropArea: 1,
        responsive: true,
        croppedCanvasOptions: {},
      },
      actions: {
        revert: true,
        rotate: true,
        granularRotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: aspectRatio ? false : true,
        cropWidescreen: aspectRatio ? false : true,
        cropWidescreenVertical: aspectRatio ? false : true,
      },
    })
  }, [aspectRatio, onUploading, uppy])

  useEffect(() => {
    const plugin = uppy.getPlugin<BasePlugin<AwsS3Options>>('AwsS3')
    plugin?.setOptions({
      timeout: ms('1 minute'),
      getUploadParameters: async file => {
        const fileId = nanoid()
        const _path = path.endsWith('/') ? path.slice(0, -1) : path
        const filepath = getUploadFilePath(`${_path}/${fileId}`, file.name)

        const { fileUrl, signedUrl } = await getUploadUrl({
          appId,
          filepath,
          file: file.data,
          authToken,
          ACL: 'public-read',
        })
        setFileUrl(fileUrl)

        const { searchParams } = new URL(signedUrl)
        const query = Object.fromEntries(searchParams)
        return {
          method: 'PUT',
          url: signedUrl,
          headers: {
            ...query,
            'Content-Type': file.type ?? '',
            'Cache-Control': 'public,max-age=31536000',
            'x-amz-acl': 'public-read',
          },
        }
      },
    })
  }, [appId, authToken, path, uppy])

  useEffect(() => {
    uppy.on('upload-success', (file, data) => {
      if (fileUrl) {
        onChange?.(fileUrl)
        onSuccess?.(fileUrl, file?.data as File)
      }
    })
  }, [fileUrl, onChange, onSuccess, uppy])

  useEffect(() => {
    uppy.on('upload-error', (file, error) => {
      console.error(error)
      onError?.(error)
    })
  }, [onError, uppy])

  return (
    <DashboardModal
      open={open}
      uppy={uppy}
      closeAfterFinish
      autoOpenFileEditor
      onRequestClose={() => onOpenChange?.(false)}
      plugins={['ImageEditor']}
    />
  )
}

export default UploadImageModal
