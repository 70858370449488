import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import { Node, NodeTree, useEditor, useNode } from '@craftjs/core'
import { getRandomId } from '@craftjs/utils'
import clsx from 'clsx'
import { cloneDeep } from 'lodash-es'
import React from 'react'
import { styled } from 'styled-components'

const StyledElementControlsDiv = styled.div`
  position: absolute;
  top: -30px;
  height: 30px;
  font-size: 12px;
  line-height: 12px;
  background: #000;
  color: #fff;
  transition: 0.2s ease-in-out opacity;

  svg {
    fill: #fff;
    width: 15px;
    height: 15px;
  }

  .button {
    padding: 0 0px;
    opacity: 0.9;
    display: flex;
    align-items: center;
    cursor: pointer;
    > div {
      position: relative;
      top: -50%;
      left: -50%;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  &.selected {
    opacity: 0.5;
  }
  &.hovered {
    opacity: 1;
    z-index: 9999;
  }
`
const ElementControls: React.FC = () => {
  const { id } = useNode()
  const { actions, query, isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }))

  const {
    isHover,
    isRoot,
    isFirstNode,
    isLastNode,
    isLocked,
    nodeId,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
  } = useNode(node => {
    const parent = node.data.parent
    const parentNodeIndex = parent ? query.node(parent).get().data.nodes.indexOf(node.id) : -1

    return {
      isHover: node.events.hovered,
      isRoot: query.node(node.id).isRoot(),
      isFirstNode: parentNodeIndex === 0,
      isLastNode: parent ? parentNodeIndex === query.node(parent).get().data.nodes.length - 1 : false,
      isLocked: node.data.props.isLocked ?? false,
      nodeId: node.id,
      dom: node.dom,
      name: (node.data.custom.displayName || node.data.displayName) as string,
      moveable: query.node(node.id).isDraggable(),
      deletable: query.node(node.id).isDeletable(),
      parent,
      props: node.data.props,
    }
  })

  if (!isActive && !isHover) {
    return null
  }

  return (
    <StyledElementControlsDiv
      className={clsx('px-2 py-2 text-white bg-primary fixed d-flex align-items-center', {
        hovered: isHover,
        selected: isActive,
      })}
    >
      <div className="flex-1 mr-4">{name}</div>

      {moveable && (
        <a
          className={clsx('button mr-2', {
            disabled: isLocked,
          })}
          ref={ref => {
            if (!isLocked) {
              drag(ref!)
            }
          }}
        >
          <DragOutlined />
        </a>
      )}

      {!isRoot && (
        <a
          className={clsx('button mr-2', { disabled: isFirstNode })}
          onClick={() => {
            if (parent) {
              const nodeIndex = query.node(parent).get().data.nodes.indexOf(nodeId)
              if (!isFirstNode) {
                actions.move(nodeId, parent, nodeIndex - 1)
                actions.selectNode(nodeId)
              }
            }
          }}
        >
          <ArrowUpOutlined />
        </a>
      )}

      {!isRoot && (
        <a
          className={clsx('button mr-2', { disabled: isLastNode })}
          onClick={() => {
            if (parent) {
              const nodes = query.node(parent).get().data.nodes
              const nodeIndex = nodes.indexOf(nodeId) + 1
              if (!isLastNode) {
                actions.move(nodeId, parent, nodeIndex >= nodes.length ? nodeIndex : nodeIndex + 1)
                actions.selectNode(nodeId)
              }
            }
          }}
        >
          <ArrowDownOutlined />
        </a>
      )}

      {!isRoot && (
        <a
          className="button mr-2"
          onClick={() => {
            const parentNodeId = query.node(nodeId).get().data.parent
            if (parentNodeId) {
              const parentNodeIndex = query.node(parentNodeId).get().data.nodes.indexOf(nodeId)
              const clonedNodeTree = duplicateNodeTree(query.node(nodeId).toNodeTree())
              actions.addNodeTree(clonedNodeTree, parentNodeId, parentNodeIndex + 1)
              actions.selectNode(clonedNodeTree.rootNodeId)
            }
          }}
        >
          <CopyOutlined />
        </a>
      )}

      {!isRoot && (
        <a
          className="button mr-2"
          onClick={() => {
            actions.history.throttle().setProp(nodeId, props => {
              props.isLocked = !props.isLocked
            })
          }}
        >
          {isLocked ? <LockOutlined /> : <UnlockOutlined />}
        </a>
      )}

      {deletable && (
        <a
          className="button"
          onMouseDown={(e: React.MouseEvent) => {
            e.stopPropagation()
            actions.delete(id)
          }}
        >
          <DeleteOutlined />
        </a>
      )}
    </StyledElementControlsDiv>
  )
}

const duplicateNodeTree = (nodeTree: NodeTree) => {
  const newNodes: Record<string, Node> = {}
  const cloneNode = (rootNode: Node, parentNodeId?: string) => {
    const newNodeId = getRandomId()
    const clonedRootNode = cloneDeep(rootNode)
    const newNode: Node = {
      ...clonedRootNode,
      id: newNodeId,
      data: {
        ...clonedRootNode.data,
        parent: parentNodeId || clonedRootNode.data.parent,
        nodes: clonedRootNode.data.nodes
          ? clonedRootNode.data.nodes.map(nodeId => cloneNode(nodeTree.nodes[nodeId], newNodeId))
          : [],
        linkedNodes: Object.keys(clonedRootNode.data.linkedNodes).reduce((acc, key) => {
          acc[key] = cloneNode(nodeTree.nodes[clonedRootNode.data.linkedNodes[key]], newNodeId)
          return acc
        }, {} as Record<string, string>),
      },
      events: {
        ...clonedRootNode.events,
        selected: false,
        hovered: false,
        dragged: false,
      },
    }
    newNodes[newNodeId] = newNode
    return newNodeId
  }

  const newNodeTree: NodeTree = {
    rootNodeId: cloneNode(nodeTree.nodes[nodeTree.rootNodeId]),
    nodes: newNodes,
  }
  return newNodeTree
}

export default ElementControls
