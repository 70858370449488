'use client'

import { defineMessages } from '@formatjs/intl'
import { Button } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import CardListBlock from 'src/components/blockLayout/CardListBlock'
import PageSection from 'src/components/blockLayout/PageSection'
import CourseCard from 'src/components/course/CourseCard'
import { CourseBriefProps } from 'src/types/course'
import { ListSectionProps } from '../ListSection/ListSection'

export type CourseListSectionProps = ListSectionProps & {
  categoryId?: string | null
  loadingCourses?: boolean
  courses?: CourseBriefProps[]
}

const intlMessages = defineMessages({
  allCourses: {
    id: 'components.CourseListSection.title.allCourses',
    defaultMessage: '所有課程',
  },
  showMore: {
    id: 'components.CourseListSection.label.showMore',
    defaultMessage: '顯示更多課程',
  },
})

const CourseListSection: React.FC<CourseListSectionProps> = ({
  loadingCourses,
  courses = [],
  responsiveOptions,
  title,
  buttonText,
  buttonPosition = 'right',
  buttonSize = 'middle',
  listAlignment = 'alignLeft',
}) => {
  const { formatMessage } = useIntl()
  if (!loadingCourses && courses.length === 0) {
    return null
  }

  return (
    <PageSection
      loading={loadingCourses}
      center
      title={title || formatMessage(intlMessages.allCourses)}
      extra={
        buttonPosition !== 'bottom' ? (
          <Button href="/courses" shape="round" size={buttonSize}>
            <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
            <span className="ml-1">»</span>
          </Button>
        ) : undefined
      }
      extraPosition={buttonPosition === 'bottom' ? undefined : buttonPosition}
    >
      <CardListBlock
        className="mt-3 mt-sm-4"
        responsiveOptions={responsiveOptions}
        listAlignment={listAlignment}
        loading={loadingCourses}
        dataSource={courses}
        render={(course, index) => <CourseCard course={course} priority={index === 0} />}
      />

      {buttonPosition === 'bottom' && (
        <div className="text-center mt-4">
          <Button href="/courses" shape="round" size={buttonSize}>
            <div className="px-3">
              <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
              <span className="ml-1">»</span>
            </div>
          </Button>
        </div>
      )}
    </PageSection>
  )
}

export default CourseListSection
