'use client'

import { toolize } from '../..'
import HighlightSection, { HighlightSectionProps } from './HighlightSection'

const HighlightSectionToolize: React.FC<HighlightSectionProps> = props => {
  return <HighlightSection {...props} />
}

export default toolize(HighlightSectionToolize)
