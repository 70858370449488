'use client'

import { defineMessages } from '@formatjs/intl'
import { Form, Input, Switch, Space, Button, Select, ColorPicker } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import StepNumberInput from 'src/components/common/input/StepNumberInput'
import UploadImage from 'src/components/common/upload/UploadImage'
import { ElementSettings } from '../..'
import { HighlightSectionProps } from './HighlightSection'

export const intlMessages = defineMessages({
  imageUrl: { id: 'pages.editor.settings.HighlightSection.imageUrl', defaultMessage: '圖片上傳' },
  bgColorPicker: { id: 'pages.editor.settings.HighlightSection.bgColorPicker', defaultMessage: '設定背景顏色' },
  isFullWidth: {
    id: 'pages.editor.settings.HighlightSection.isFullWidth',
    defaultMessage: '全寬模式',
  },
  title: { id: 'pages.editor.settings.HighlightSection.title', defaultMessage: '標題' },
  subtitle: {
    id: 'pages.editor.settings.HighlightSection.subtitle',
    defaultMessage: '副標題',
  },
  imagePosition: { id: 'pages.editor.settings.HighlightSection.imagePosition', defaultMessage: '圖片位置' },
  right: { id: 'pages.editor.settings.HighlightSection.imagePosition.right', defaultMessage: '右邊' },
  left: { id: 'pages.editor.settings.HighlightSection.imagePosition.left', defaultMessage: '左邊' },
  featuresType: { id: 'pages.editor.settings.HighlightSection.featuresType', defaultMessage: '描述方式' },
  bullet: { id: 'pages.editor.settings.HighlightSection.featuresType.bullet', defaultMessage: '列點' },
  text: { id: 'pages.editor.settings.HighlightSection.featuresType.text', defaultMessage: '文字' },
  features: {
    id: 'pages.editor.settings.HighlightSection.features',
    defaultMessage: '列點描述',
  },
  textArea: { id: 'pages.editor.settings.HighlightSection.textArea', defaultMessage: '描述文字' },
  textPosition: { id: 'pages.editor.settings.HighlightSection.textPosition', defaultMessage: '文字對齊位置' },
  top: { id: 'pages.editor.settings.HighlightSection.textPosition.top', defaultMessage: '上' },
  middle: { id: 'pages.editor.settings.HighlightSection.textPosition.middle', defaultMessage: '中' },
  bottom: { id: 'pages.editor.settings.HighlightSection.textPosition.bottom', defaultMessage: '下' },
  isWhiteText: { id: 'pages.editor.settings.HighlightSection.isBlackText', defaultMessage: '文字是否反白' },
  needButton: { id: 'pages.editor.settings.HighlightSection.needButton', defaultMessage: '需要加入會員按鈕嗎？' },
  buttonContent: { id: 'pages.editor.settings.HighlightSection.buttonContent', defaultMessage: '按鈕文字' },
  buttonLink: { id: 'pages.editor.settings.HighlightSection.buttonLink', defaultMessage: '按鈕連結' },
})

const HighlightSectionSettings: ElementSettings<HighlightSectionProps> = ({ props, onPropsChange }) => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const onValuesChange = useCallback(async () => {
    try {
      const newProps = await form.validateFields()
      onPropsChange?.(newProps)
    } catch (error) {}
  }, [form, onPropsChange])

  const onImageSize = useCallback(
    (width: number, height: number) => {
      form.setFields([
        { name: 'imageWidth', value: width },
        { name: 'imageHeight', value: height },
      ])
    },
    [form],
  )

  const [featuresType, setFeaturesType] = useState<string>('')

  useEffect(() => {
    const validateForm = async () => {
      try {
        const newProps = await form.validateFields()
        if (newProps.featuresType === 'bullet') {
          setFeaturesType('bullet')
        } else {
          setFeaturesType('text')
        }
      } catch (error) {
        console.error(error)
      }
    }

    validateForm()
  }, [form, onPropsChange])

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={onValuesChange}
      initialValues={{
        paddingY: 0,
        imagePosition: 'right',
        textPosition: 'center',
        title: formatMessage(intlMessages.title),
        subtitle: formatMessage(intlMessages.subtitle),
        featuresType: 'bullet',
        features: ['列點描述1', '列點描述2', '列點描述3'],
        buttonContent: formatMessage(intlMessages.buttonContent),
        ...props,
      }}
    >
      <Form.Item name="imageUrl" label={formatMessage(intlMessages.imageUrl)}>
        <UploadImage path="/page-builder/images/" onImageSize={onImageSize} />
      </Form.Item>

      <Form.Item name="imageWidth" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="imageHeight" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="imagePosition" label={formatMessage(intlMessages.imagePosition)}>
        <Select
          options={[
            { label: formatMessage(intlMessages.right), value: 'right' },
            { label: formatMessage(intlMessages.left), value: 'left' },
          ]}
        />
      </Form.Item>

      <Form.Item name="bgColorPicker" label={formatMessage(intlMessages.bgColorPicker)}>
        <ColorPicker
          defaultValue="#FDFBF5"
          panelRender={panel => (
            <div className="custom-panel">
              <div
                style={{
                  fontSize: 12,
                  color: 'rgba(0, 0, 0, 0.88)',
                  lineHeight: '20px',
                  marginBottom: 8,
                }}
              >
                調色盤
              </div>
              {panel}
            </div>
          )}
        />
      </Form.Item>

      <Form.Item name="isFullWidth" label={formatMessage(intlMessages.isFullWidth)} valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item name="textPosition" label={formatMessage(intlMessages.textPosition)}>
        <Select
          options={[
            { label: formatMessage(intlMessages.top), value: 'flex-start' },
            { label: formatMessage(intlMessages.middle), value: 'center' },
            { label: formatMessage(intlMessages.bottom), value: 'flex-end' },
          ]}
        />
      </Form.Item>

      <Form.Item name="isWhiteText" label={formatMessage(intlMessages.isWhiteText)}>
        <Switch />
      </Form.Item>

      <Form.Item name="title" label={formatMessage(intlMessages.title)}>
        <Input />
      </Form.Item>

      <Form.Item name="subtitle" label={formatMessage(intlMessages.subtitle)}>
        <Input />
      </Form.Item>

      <Form.Item name="featuresType" label={formatMessage(intlMessages.featuresType)}>
        <Select
          options={[
            { label: formatMessage(intlMessages.bullet), value: 'bullet' },
            { label: formatMessage(intlMessages.text), value: 'text' },
          ]}
          onChange={value => {
            setFeaturesType(value)
          }}
        />
      </Form.Item>

      {featuresType === 'bullet' ? (
        <Form.Item label={formatMessage(intlMessages.features)}>
          <Form.List name="features">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <Form.Item key={field.key} required={false}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[{ required: true, whitespace: true, message: '請輸入特色描述或刪除此欄位' }]}
                      noStyle
                    >
                      <Input placeholder="特色描述" style={{ width: '90%' }} onChange={onValuesChange} />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                      style={{ marginLeft: 8 }}
                    />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                    增加描述
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      ) : (
        <Form.Item label={formatMessage(intlMessages.textArea)} name="textArea">
          <Input.TextArea autoSize showCount />
        </Form.Item>
      )}

      <Form.Item name="needButton" label={formatMessage(intlMessages.needButton)} valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="buttonContent" label={formatMessage(intlMessages.buttonContent)}>
        <Input />
      </Form.Item>
      <Form.Item name="buttonLink" label={formatMessage(intlMessages.buttonLink)}>
        <Input />
      </Form.Item>
    </Form>
  )
}

export default HighlightSectionSettings
