'use client'

import { useMerchandises } from 'src/hooks/merchandise.client'
import { toolize } from '../..'
import MerchandiseListSection, { MerchandiseListSectionProps } from './MerchandiseListSection'

const MerchandiseListSectionToolize: React.FC<MerchandiseListSectionProps> = ({ categoryId, ...props }) => {
  const { loadingMerchandises, merchandises } = useMerchandises({ categoryId, limit: 4 })

  return <MerchandiseListSection loadingMerchandises={loadingMerchandises} merchandises={merchandises} {...props} />
}

export default toolize(MerchandiseListSectionToolize)
