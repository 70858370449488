import { KeenSliderInstance, KeenSliderPlugin } from 'keen-slider'
import { MutableRefObject } from 'react'

export const autoSwitchPlugin = (autoSwitchSlideInterval: number | undefined) => {
  const plugin: KeenSliderPlugin = slider => {
    if (autoSwitchSlideInterval) {
      let timeout: number
      let mouseOver = false
      const clearNextTimeout = () => {
        clearTimeout(timeout)
      }
      const nextTimeout = () => {
        clearTimeout(timeout)
        if (mouseOver) return
        timeout = window.setTimeout(() => {
          slider.next()
        }, autoSwitchSlideInterval)
      }
      slider.on('created', () => {
        slider.container.addEventListener(
          'mouseover',
          () => {
            mouseOver = true
            clearNextTimeout()
          },
          { passive: true },
        )
        slider.container.addEventListener(
          'mouseout',
          () => {
            mouseOver = false
            nextTimeout()
          },
          { passive: true },
        )
        nextTimeout()
      })
      slider.on('dragStarted', clearNextTimeout)
      slider.on('animationEnded', nextTimeout)
      slider.on('updated', nextTimeout)
    }
  }

  return plugin
}

export function thumbnailPlugin(mainRef: MutableRefObject<KeenSliderInstance | null>): KeenSliderPlugin {
  return slider => {
    function removeActive() {
      slider.slides.forEach(slide => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', main => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}
