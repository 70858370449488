'use client'

import { useMemberPublics } from 'src/hooks/member.client'
import { toolize } from '../..'
import InstructorListSection, { InstructorListSectionProps } from './InstructorListSection'

const InstructorListSectionToolize: React.FC<InstructorListSectionProps> = props => {
  const { members, loadingMembers } = useMemberPublics({ limit: 4, isDetailShown: true, instructorType: 'all' })

  return <InstructorListSection members={members} loadingMembers={loadingMembers} {...props} />
}

export default toolize(InstructorListSectionToolize)
