const emptyOrSuffix = (value: number | string, prefix: string) => {
  if (!value) return ''
  return `${value}${prefix}`
}

/**
 * @param seconds
 * @returns string HH:mm:ss/mm:ss/ss
 */
export const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) % 60
  const secs = Math.floor(seconds % 60)
  return `${hours > 0 ? `${hours.toString().padStart(2, '0')}:` : ''}${minutes.toString().padStart(2, '0')}:${secs
    .toString()
    .padStart(2, '0')}`
}

/**
 *
 * @param seconds
 * @returns string H 小時 m 分/m 分
 */
export const secondsToChTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60)
  if (hours === 0) return `${minutes} 分`
  return `${hours} 小時 ${minutes} 分`
}

export const getRemainingTime = (targetTime: Date) => {
  const now = new Date()
  const distance = targetTime.getTime() - now.getTime()
  const countDownTime = distance >= 0 ? distance : 0

  const _second = 1000
  const _minute = _second * 60
  const _hour = _minute * 60
  const _day = _hour * 24

  const days = Math.floor(countDownTime / _day)
  const hours = Math.floor((countDownTime % _day) / _hour)
  const minutes = Math.floor((countDownTime % _hour) / _minute)
  const seconds = Math.floor((countDownTime % _minute) / _second)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}
