'use client'

import { CheckCircleFilled } from '@ant-design/icons'
import { Button } from 'antd'
import clsx from 'clsx'
import Link from 'next/link'
import { useIntl } from 'react-intl'
import PlaceholderImage from 'src/assets/images/image-placeholder.jpg'
import LazyLoadImage from 'src/components/common/LazyLoadImage'
import { BREAK_POINT, BREAK_POINT_MD, BREAK_POINT_SM, BREAK_POINT_XL } from 'src/contexts/ResponsiveContext'
import { styled } from 'styled-components'
import { intlMessages } from './HighlightSection.settings'

export type HighlightSectionProps = {
  isEditing?: boolean
  isFullWidth?: boolean
  paddingY?: number
  imageUrl?: string
  imageAlt?: string
  imageWidth?: number
  imageHeight?: number
  title?: string
  subtitle?: string
  imagePosition?: string
  featuresType?: string
  features?: string[]
  textArea?: string
  bgColorPicker?: any
  textPosition?: string
  isWhiteText?: boolean
  needButton?: boolean
  buttonContent?: string
  buttonLink?: string
}

const StyledImageSection = styled.div`
  .image-wrapper {
    position: relative;
    width: auto;
    max-width: 50%;
    height: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: ${BREAK_POINT}px) {
      max-width: 100%;
      width: 60%;
    }
    @media screen and (max-width: ${BREAK_POINT_MD}px) {
      width: 70%;
    }
    @media screen and (max-width: ${BREAK_POINT_SM}px) {
      width: 80%;
    }
  }
  .image-wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`

const SectionLayout = styled.div<{ bgColorPicker: any; isFullWidth?: boolean }>`
  height: ${props => (props.isFullWidth ? '550px' : '450px')};
  background-color: ${props =>
    `rgba(${props.bgColorPicker?.metaColor.r},${props.bgColorPicker?.metaColor.g},${props.bgColorPicker?.metaColor.b},${props.bgColorPicker?.metaColor.a})`};
  padding: 0 100px;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${BREAK_POINT}px) {
    height: auto;
    padding: 50px 0px;
  }
`

const ElementLayout = styled.div<{ imagePosition?: string; isFullWidth?: boolean; textPosition?: string }>`
  display: flex;
  flex-direction: ${({ imagePosition }) => (imagePosition === 'right' ? 'row' : 'row-reverse')};
  justify-content: ${({ isFullWidth }) => (isFullWidth ? 'center' : 'space-between')};
  align-items: ${({ textPosition }) => textPosition};
  width: 100%;
  height: 70%;
  gap: ${({ isFullWidth }) => (isFullWidth ? '200px' : '50px')};

  @media screen and (max-width: ${BREAK_POINT_XL}px) {
    gap: ${({ isFullWidth }) => (isFullWidth ? '5vw' : '5vw')};
  }

  @media screen and (max-width: ${BREAK_POINT}px) {
    flex-direction: ${({ imagePosition }) => (imagePosition === 'right' ? 'column' : 'column-reverse')};
    gap: 50px;
  }
`

const TextLayout = styled.div<{ isImageValid: boolean; imagePosition: string; isFullWidth?: boolean }>`
  width: ${props =>
    props.isImageValid ? (props.isFullWidth ? '40%' : props.imagePosition === 'left' ? '45%' : '60%') : '100%'};
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${BREAK_POINT}px) {
    width: 60%;
  }
  @media screen and (max-width: ${BREAK_POINT_MD}px) {
    width: 70%;
  }
  @media screen and (max-width: ${BREAK_POINT_SM}px) {
    width: 80%;
  }
`

const FeatureItem: React.FC<{ children: React.ReactNode; isWhiteText?: boolean }> = ({ children, isWhiteText }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <div>
      <span
        style={{
          fontSize: '15px',
          fontWeight: '300',
        }}
        aria-hidden="true"
      >
        <CheckCircleFilled />
      </span>
    </div>
    <div style={{ fontSize: '0.8rem', letterSpacing: '0.05rem', lineHeight: '1.2rem' }}>{children}</div>
  </div>
)

const HighlightSection: React.FC<HighlightSectionProps> = ({
  isEditing,
  isFullWidth,
  paddingY,
  imageUrl,
  imageAlt,
  imageWidth,
  imageHeight,
  title,
  subtitle,
  imagePosition = 'right',
  featuresType = 'bullet',
  features = ['列點描述1', '列點描述2', '列點描述3'],
  textArea,
  bgColorPicker,
  textPosition,
  isWhiteText,
  needButton,
  buttonContent,
  buttonLink,
}) => {
  const { formatMessage } = useIntl()
  const isImageValid = Boolean(imageUrl) && Boolean(imageWidth) && Boolean(imageHeight)
  const ImageBlock: React.FC = () => (
    <div className="image-wrapper">
      {isImageValid && imageUrl ? (
        <LazyLoadImage src={imageUrl} alt={imageAlt || ''} width={imageWidth} height={imageHeight} />
      ) : isEditing ? (
        <LazyLoadImage alt="" {...PlaceholderImage} />
      ) : null}
    </div>
  )

  return (
    <StyledImageSection
      className={clsx({ container: !isFullWidth })}
      style={{
        paddingTop: paddingY,
        paddingBottom: paddingY,
        overflow: 'hidden',
      }}
    >
      <SectionLayout isFullWidth={isFullWidth} bgColorPicker={bgColorPicker}>
        <ElementLayout imagePosition={imagePosition} isFullWidth={isFullWidth} textPosition={textPosition}>
          <TextLayout isImageValid={isImageValid} isFullWidth={isFullWidth} imagePosition={imagePosition}>
            <h1
              style={{
                fontSize: '1.6rem',
                lineHeight: '2.25rem',
                color: `${isWhiteText ? 'white' : 'black'}`,
              }}
            >
              {title || formatMessage(intlMessages.title)}
            </h1>
            <p
              style={{
                fontSize: '0.95rem',
                lineHeight: '1.5',
                color: `${isWhiteText ? 'white' : 'black'}`,
                fontWeight: 500,
              }}
            >
              {subtitle || formatMessage(intlMessages.subtitle)}
            </p>
            {featuresType === 'bullet' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '10px',
                  color: `${isWhiteText ? 'white' : 'black'}`,
                }}
              >
                {features && features.map((feature, i) => <FeatureItem key={i}>{feature}</FeatureItem>)}
              </div>
            ) : (
              <div
                style={{
                  fontSize: '0.8rem',
                  letterSpacing: '0.05rem',
                  lineHeight: '1.2rem',
                  whiteSpace: 'pre-wrap',
                  color: `${isWhiteText ? 'white' : 'black'}`,
                }}
              >
                {textArea || formatMessage(intlMessages.textArea)}
              </div>
            )}
            {needButton ? (
              <Button
                style={{
                  alignSelf: 'flex-start',
                  minWidth: '80px',
                  marginTop: '20px',
                  backgroundColor: `${isWhiteText ? 'white' : 'black'}`,
                  color: `${isWhiteText ? 'black' : 'white'}`,
                  border: 'none',
                }}
              >
                {buttonLink ? (
                  <Link href={buttonLink} target="_blank">
                    {buttonContent || formatMessage(intlMessages.buttonContent)}
                  </Link>
                ) : (
                  buttonContent
                )}
              </Button>
            ) : null}
          </TextLayout>
          {isImageValid && <ImageBlock />}
        </ElementLayout>
      </SectionLayout>
    </StyledImageSection>
  )
}

export default HighlightSection
