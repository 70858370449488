'use client'

import { UserOutlined } from '@ant-design/icons'
import { FundraisingDisplayMode } from '@havppen/types/src/course'
import { FundraisingTargetUnit } from '@havppen/types/src/product'
import { Progress } from 'antd'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { CourseBriefProps } from 'src/types/course'
import { getRemainingTime } from 'src/utils/time'
import styled from 'styled-components'
import ProductCard, { ProductCardCommonProps, ProductCardContent } from '../common/ProductCard'

const StyledCourseCardFundraisingBlock = styled.div<{ isCountdownVisible?: boolean }>`
  color: var(--theme-secondary-color);
  font-size: 12px;
  min-height: 64px;
  .price-tag-block {
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.isCountdownVisible ? 'flex-end' : 'flex-start')};
  }
  .price-tag-block,
  .price-tag {
    line-height: 1;
  }
  .price-tag {
    font-size: 20px;
    font-weight: 500;
  }
  .list-price-tag {
    text-decoration: line-through;
    font-size: 12px;
  }
  .ant-progress-bg {
    background-color: var(--theme-fundraising-color);
  }
  .highlight {
    color: var(--theme-fundraising-color);
  }
`
const CourseCardFundraisingBlock: React.FC<{
  fundraisingDisplayMode?: FundraisingDisplayMode
  isPriceHidden?: boolean
  isFundraisingCountdownVisible: boolean | null
  isFundraisingParticipantsVisible: boolean | null
  fundraisingPricingMode: string | null
  fundraisingTargetUnit: FundraisingTargetUnit
  fundraisingTargetAmount: number | null
  fundraisingDueAt: Date | null
  listPrice: number
  salePrice: number | null
  purchaseCount: number
  totalSales: number
}> = ({
  fundraisingDisplayMode = 'preorder',
  isPriceHidden,
  isFundraisingCountdownVisible,
  isFundraisingParticipantsVisible,
  fundraisingDueAt,
  fundraisingTargetUnit,
  fundraisingTargetAmount,
  listPrice,
  salePrice,
  purchaseCount,
  totalSales,
}) => {
  const remainingTime = getRemainingTime(fundraisingDueAt ? new Date(fundraisingDueAt) : new Date())

  const progressAmount = fundraisingTargetUnit === 'participants' ? purchaseCount : totalSales
  const baseAmount = fundraisingTargetAmount || progressAmount
  const progressPercentage = baseAmount === 0 ? 0 : Math.round((progressAmount / baseAmount) * 100)

  const isCountdownVisible = Boolean(isFundraisingCountdownVisible) && remainingTime.days > 0

  return (
    <StyledCourseCardFundraisingBlock isCountdownVisible={isCountdownVisible}>
      <div className="d-flex justify-content-between align-items-end">
        {isCountdownVisible && <span>倒數{remainingTime.days}天</span>}

        <div className={clsx('price-tag-block', isPriceHidden && 'd-none')}>
          {salePrice !== null && <span className="list-price-tag">NT${listPrice.toLocaleString()}</span>}
          <span className="price-tag highlight pt-1">NT${(salePrice ?? listPrice)?.toLocaleString()}</span>
        </div>
      </div>

      {isFundraisingParticipantsVisible && (
        <>
          <Progress percent={progressPercentage} showInfo={false} />

          <div className="d-flex justify-content-between align-items-center mt-1">
            <span>
              <UserOutlined />
              <span className="ml-1">
                <span>{fundraisingDisplayMode === 'fundraising' ? '已募資' : '已預購'}</span>
                <span>{purchaseCount}</span>
                <span>人</span>
              </span>
            </span>
            <span className="highlight">
              {progressPercentage > 0 ? '已達' : ''}
              {progressPercentage}%
            </span>
          </div>
        </>
      )}
    </StyledCourseCardFundraisingBlock>
  )
}

const CourseCard: React.FC<
  ProductCardCommonProps & {
    course: CourseBriefProps
    priority?: boolean
    expiredAt?: Date | null
    totalProgressPercentage?: number | null
    isVisible?: boolean
  }
> = ({ course, priority, isPurchased, expiredAt, totalProgressPercentage, isVisible, ...props }) => {
  const isFundraising = course.status === 'fundraising'

  const fundraisingParticipantsShowBaseLimit = course.styleAttributes?.fundraisingParticipantsShowBaseLimit ?? 0
  const isFundraisingParticipantsVisible =
    (course.styleAttributes.isFundraisingParticipantsVisible ?? true) &&
    course.purchaseCount >= fundraisingParticipantsShowBaseLimit
  const isFundraisingCountdownVisible = course.styleAttributes.isFundraisingCountdownVisible ?? true

  const fundraisingDisplayMode = course.styleAttributes.fundraisingDisplayMode ?? 'preorder'
  const courseStatus = useMemo(() => {
    if (course.status === 'fundraising') {
      return fundraisingDisplayMode === 'fundraising' ? 'fundraising' : 'preorder'
    } else {
      return 'published'
    }
  }, [course.status, fundraisingDisplayMode])

  const isFundraisingOrPreorder = course?.status === 'fundraising' || course?.status === 'preorder'
  const isCourseContentDurationVisible = isFundraisingOrPreorder
    ? course?.styleAttributes.isFundraisingCourseContentDurationVisible ?? true
    : true

  return (
    <ProductCard
      productType="Course"
      productTarget={course.id}
      productStatus={courseStatus}
      featuredImageUrl={course.featuredImageUrl}
      title={course.title}
      startSellAt={course.fundraisingStartAt}
      path={course.path}
      members={course.instructors}
      priority={priority}
      expiredAt={expiredAt}
      isVisible={isVisible}
      isAuthorInfoHidden={course.isAuthorInfoHidden}
      duration={isCourseContentDurationVisible ? course.contentDuration ?? 0 : undefined}
      {...props}
    >
      {isFundraising ? (
        <CourseCardFundraisingBlock
          fundraisingDisplayMode={fundraisingDisplayMode}
          isFundraisingCountdownVisible={isFundraisingCountdownVisible}
          isFundraisingParticipantsVisible={isFundraisingParticipantsVisible}
          isPriceHidden={course.styleAttributes.isPriceHidden}
          fundraisingPricingMode={course.fundraisingPricingMode}
          fundraisingTargetUnit={course.fundraisingTargetUnit}
          fundraisingTargetAmount={course.fundraisingTargetAmount}
          fundraisingDueAt={course.fundraisingDueAt}
          listPrice={course.listPrice}
          salePrice={course.salePrice}
          purchaseCount={course.purchaseCount}
          totalSales={course.totalSales}
        />
      ) : (
        <ProductCardContent
          listPrice={course.listPrice}
          salePrice={course.salePrice}
          averageRating={course.averageRating}
          reviewCount={course.reviewCount}
          purchaseCount={course.purchaseCount}
          totalSales={course.totalSales}
          isOpenForSale={course.status === 'published'}
          isPriceHidden={course.styleAttributes.isPriceHidden}
          isPurchased={isPurchased}
          openForSaleText={<FormattedMessage id="course.card.published" />}
          isParticipantCountHidden={course.isParticipantCountHidden}
          isDiscountCountdownVisible={course.isDiscountCountdownVisible}
          totalProgressPercentage={totalProgressPercentage}
          expiredAt={expiredAt}
        />
      )}
    </ProductCard>
  )
}

export default CourseCard
