'use client'

import { Layout as AntdLayout } from 'antd'
import React from 'react'
import { styled } from 'styled-components'

const StyledLayout = styled(AntdLayout)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: white;
`
const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>
}

export default Layout
