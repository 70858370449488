'use client'

import { faBookmark } from '@fortawesome/free-regular-svg-icons/faBookmark'
import { faBookmark as faBookmartSolid } from '@fortawesome/free-solid-svg-icons/faBookmark'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isBrightColor } from '@havppen/utils/src/color'
import { Button } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import React, { useContext } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { useIsMemberFollowing, useMemberFollowMutation } from 'src/hooks/member.client'
import { MemberPublicDetailProps } from 'src/types/member'
import styled from 'styled-components'
import AuthModalContext from '../auth/AuthModalContext'

const StyledInstructorCardDiv = styled.div`
  width: 100%;
  height: 100%;
  max-width: var(--card-max-width);
  margin: auto;

  .emphasis-text {
    color: ${props =>
      isBrightColor(props.theme['primary-color']) ? props.theme['primary-color'] : props.theme['secondary-color']};
  }

  .avatar-block {
    width: 100%;
    height: 300px;
    box-shadow: var(--theme-box-shadow);
    background-color: white !important;

    img {
      object-fit: cover;
      object-position: center;
    }
    &,
    img {
      border-radius: var(--default-border-radius, 16px);
    }
  }

  .teacher-metadata {
    position: absolute;
    width: calc(100% - 24px);
    bottom: 12px;
    left: 12px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    border-radius: var(--default-border-radius, 16px);

    a,
    a:hover {
      color: #ffffff;
    }

    .instructor-name-block {
      min-height: 56px;
    }

    .name {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .title {
      font-size: 12px;
      line-height: 1.4;
      max-width: calc(100% - 36px);
    }
    .info {
      font-size: 10px;
    }
    .following-button-box {
      position: absolute;
      right: 15px;
      top: 16px;
    }
  }
`

const StyledFollowButtonDiv = styled.div`
  font-size: 12px;
  cursor: pointer;
`
const FollowMemberButton: React.FC<React.PropsWithChildren<{ memberId: string }>> = ({ memberId }) => {
  const { isAuthenticated } = useAuth()
  const { setVisible: setAuthModalVisible } = useContext(AuthModalContext)
  const { loadingIsMemberFollowing, isMemberFollowing } = useIsMemberFollowing(memberId)
  const { insertMemberFollowing, deleteMemberFollowing } = useMemberFollowMutation(memberId)

  return (
    <StyledFollowButtonDiv
      className="d-flex flex-column align-items-center"
      onClick={async () => {
        if (loadingIsMemberFollowing) {
          return
        }

        if (!isAuthenticated) {
          setAuthModalVisible?.(true)
          return
        }

        if (isMemberFollowing) {
          await deleteMemberFollowing()
        } else {
          await insertMemberFollowing()
        }
      }}
    >
      <Button
        type={isMemberFollowing ? 'primary' : 'default'}
        ghost
        shape="circle"
        icon={<FontAwesomeIcon icon={isMemberFollowing ? faBookmartSolid : faBookmark} />}
        aria-label={isMemberFollowing ? '取消追蹤' : '追蹤'}
      />
      <div className="mt-1">{isMemberFollowing ? '已追蹤' : '追蹤'}</div>
    </StyledFollowButtonDiv>
  )
}

const InstructorCard: React.FC<React.PropsWithChildren<{ member: MemberPublicDetailProps; customUrl?: string }>> = ({
  member,
  customUrl,
}) => {
  const { defaultAvatarUrl } = useApp()
  const avatarUrl = member.avatarUrl || defaultAvatarUrl
  const memberName = member.name ?? ''

  return (
    <StyledInstructorCardDiv className="position-relative">
      <Link href={customUrl || `/member/${member.id}`} aria-label={memberName}>
        <div className="avatar-block">
          {avatarUrl && (
            <Image
              src={avatarUrl}
              alt={memberName}
              fill
              loading="lazy"
              unoptimized={avatarUrl.endsWith('.svg') || avatarUrl.endsWith('.gif')}
            />
          )}
        </div>
      </Link>

      <div className="position-relative teacher-metadata">
        <Link href={customUrl || `/member/${member.id}`} aria-label={memberName}>
          <div className="p-3">
            <div className="d-flex justify-content-between instructor-name-block">
              <div>
                <div className="name">{memberName}</div>
                <div className="title">{member.title}</div>
              </div>
            </div>
            <div className="d-flex mt-3 justify-content-between align-items-center">
              <div className="info">
                已開精選
                <span className="emphasis-text"> {member.totalProductCount}堂</span>
              </div>
              <div className="info">
                追蹤人數
                <span className="emphasis-text"> {member.followerCount}人</span>
              </div>
            </div>
          </div>
        </Link>

        <div className="following-button-box">
          <FollowMemberButton memberId={member.id} />
        </div>
      </div>
    </StyledInstructorCardDiv>
  )
}

export default InstructorCard
