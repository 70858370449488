'use client'

import { useActivities } from 'src/hooks/activity.client'
import { toolize } from '../..'
import ActivityListSection, { ActivityListSectionProps } from './ActivityListSection'

const ActivityListSectionToolize: React.FC<ActivityListSectionProps> = ({ categoryId, ...props }) => {
  const { loadingActivities, activities } = useActivities({
    limit: 4,
    showExpired: true,
    categoryId,
  })
  return <ActivityListSection loadingActivities={loadingActivities} activities={activities} {...props} />
}
export default toolize(ActivityListSectionToolize)
