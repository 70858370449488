import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { MerchandiseType } from '@havppen/types/src/merchandise'
import { ProductOrderType, ProductStatus } from '@havppen/types/src/product'
import {
  productMemberTagRestrictionFilterTypes,
  productMemberTagRestrictionTypes,
  productVisibilityTypes,
} from 'src/types/productVisibility'
import { NIL as NIL_UUID } from 'uuid'

export type MerchandiseQueryOptions = {
  sellerId?: string | null
  memberId?: string | null
  merchandiseIds?: string[]
  status?: ProductStatus | null
  statuses?: ProductStatus[]
  orderBy?: ProductOrderType
  search?: string | null
  categorySlug?: string | null
  categoryId?: string | null
  showPrivate?: boolean
  limit?: number
  offset?: number
  isAuthenticated?: boolean
  currentMemberId?: string
}
export const mapMerchandisesQueryOptionsToGqlVariables = (
  options: MerchandiseQueryOptions,
  params: { appId: string },
) => {
  const limit = options?.limit || 10
  const offset = options?.offset
  const orderBy: types.merchandise_order_by[] | undefined =
    options?.orderBy === 'hot'
      ? [{ position: 'asc' as types.order_by }]
      : options?.orderBy === 'recommend'
      ? [{ position: 'asc' as types.order_by }]
      : options?.orderBy === 'latest'
      ? [{ published_at: 'desc' as types.order_by }]
      : [{ position: 'asc' as types.order_by }]

  const merchandiseVisibilityQueries: types.InputMaybe<types.merchandise_bool_exp[]> = [
    {
      _not: {
        merchandise_visibility: {},
      },
    },
    {
      _not: {
        merchandise_visibility: {
          visibility_type: { _in: [productVisibilityTypes.authOnly, productVisibilityTypes.whitelist] },
        },
      },
    },
  ]
  if (options?.isAuthenticated) {
    merchandiseVisibilityQueries.push({
      merchandise_visibility: {
        visibility_type: { _eq: productVisibilityTypes.authOnly },
      },
    })
  }
  if (options?.currentMemberId && options?.currentMemberId !== NIL_UUID) {
    merchandiseVisibilityQueries.push({
      merchandise_visibility: {
        visibility_type: { _eq: productVisibilityTypes.whitelist },
        product_member_tag_restrictions: {
          type: { _eq: productMemberTagRestrictionTypes.visibility },
          filter_type: { _eq: productMemberTagRestrictionFilterTypes.include },
          member_tag_members: { member_id: { _eq: options.currentMemberId } },
        },
      },
    })
  }

  const condition: types.merchandise_bool_exp = {
    seller_id: options?.sellerId ? { _eq: options.sellerId } : undefined,
    member_id: options?.memberId ? { _eq: options.memberId } : undefined,
    is_private: options?.showPrivate ? undefined : { _eq: false },
    app_id: { _eq: params.appId },
    status: options?.status ? { _eq: options.status } : options?.statuses ? { _in: options.statuses } : undefined,
    merchandise_categories: options.categorySlug
      ? { category: { slug: { _eq: options.categorySlug } } }
      : options.categoryId
      ? { category: { id: { _eq: options.categoryId } } }
      : undefined,
    deleted_at: { _is_null: true },
    online_at: { _lte: 'now()' },
    _and: [
      { _or: [{ offline_at: { _is_null: true } }, { offline_at: { _gte: 'now()' } }] },
      {
        _or: options?.merchandiseIds
          ? [{ id: { _in: options.merchandiseIds } }, { slug: { _in: options.merchandiseIds } }]
          : options?.search
          ? [{ abstract: { _like: `%${options.search}%` } }, { content: { _like: `%${options.search}%` } }]
          : undefined,
      },
    ],
    _or: merchandiseVisibilityQueries,
  }

  const variables = {
    orderBy,
    limit,
    offset,
    condition,
  }

  return variables
}

export const formatGqlMerchandises = (data: types.GET_MERCHANDISES | undefined) => {
  return (data?.merchandise ?? []).map(merchandise => ({
    id: merchandise.id,
    title: merchandise.title,
    path: `/merchandise/${merchandise.slug ?? merchandise.id}`,
    type: merchandise.type as MerchandiseType,
    featuredImageUrl: merchandise.featured_image_url,
    coverVideoUrl: merchandise.cover_video_url,
    seoAttributes: merchandise.seo_attributes,

    isPrivate: merchandise.is_private ?? false,
    isPriceHidden: merchandise.is_price_hidden ?? false,
    isAuthorInfoHidden: merchandise.is_author_info_hidden ?? false,
    isParticipantCountHidden: merchandise.is_participant_count_hidden ?? false,
    isDiscountCountdownVisible: merchandise.is_discount_countdown_visible ?? false,

    categories: merchandise.merchandise_categories.map(merchandise_category => merchandise_category.category),
    status: merchandise.status as ProductStatus,
    position: merchandise.position,
    slug: merchandise.slug,
    seller: mapMemberPublicFromGql(merchandise.seller),
    sellerId: merchandise.seller_id,
    listPrice: merchandise.list_price,
    preorderPrice: merchandise.preorder_price,
    fundraisingPrice: merchandise.fundraising_price,
    fundraisingStartAt: merchandise.fundraising_start_at ? new Date(merchandise.fundraising_start_at) : null,
    fundraisingDueAt: merchandise.fundraising_due_at ? new Date(merchandise.fundraising_due_at) : null,
    preorderDueAt: merchandise.preorder_due_at ? new Date(merchandise.preorder_due_at) : null,
    onlineAt: merchandise.online_at ? new Date(merchandise.online_at) : null,
    offlineAt: merchandise.offline_at ? new Date(merchandise.offline_at) : null,

    reviewCount: merchandise.product_review_summary?.review_count ?? null,
    averageRating: merchandise.product_review_summary?.average_rating ?? null,
    purchaseCount: 0,
  }))
}
