'use client'

import Axios, { AxiosRequestConfig } from 'axios'

export const getUploadFilePath = (filepath: string, filename: string, keepFileName?: boolean) => {
  let _filepath = filepath.endsWith('/') ? filepath.substring(0, filepath.length - 1) : filepath
  _filepath = _filepath.startsWith('/') ? _filepath.substring(1) : _filepath
  const fileKey = keepFileName ? `${_filepath}/${filename}` : `${_filepath}.${filename?.split('.')?.pop()}`
  return fileKey
}

export const getUploadUrl = async (options: {
  appId: string
  filepath: string
  file: File | Blob | null
  authToken: string | null | undefined
  fileType?: string
  ACL?: string
}) => {
  const { appId, filepath, file, authToken, fileType, ACL } = options

  let signedUrl = ''
  let fileUrl = ''

  const origin = typeof window === 'undefined' ? '' : window.location.origin
  const apiEndpoint = `${origin}/api/v1`

  if (file) {
    const { data } = await Axios.post(
      `${apiEndpoint}/sys/sign-url`,
      {
        appId,
        filepath,
        params: {
          ContentType: fileType ?? file.type,
          ACL: ACL || 'public-read',
        },
      },
      { headers: authToken ? { authorization: `Bearer ${authToken}` } : {} },
    )

    signedUrl = data.result.signedUrl as string
    fileUrl = data.result.fileUrl as string
  }

  return { signedUrl, fileUrl }
}

export const uploadFile = async (
  options: {
    appId: string
    filepath: string
    file: File | null
    authToken: string | null | undefined
    fileType?: string
    ACL?: string
  },
  config?: AxiosRequestConfig,
) => {
  const { appId, filepath, file, authToken, fileType, ACL } = options
  const { signedUrl, fileUrl } = await getUploadUrl({ appId, filepath, file, authToken, fileType, ACL })

  if (file) {
    const { searchParams } = new URL(signedUrl)
    const query = Object.fromEntries(searchParams)
    await Axios.put<{ status: number; data: string }>(signedUrl, file, {
      ...config,
      headers: {
        ...query,
        'Content-Type': fileType ?? file.type,
        'Cache-Control': 'max-age=31536000',
      },
    })
  }

  return { signedUrl, fileUrl }
}
