'use client'

import { Skeleton } from 'antd'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import React from 'react'
import ArticleContent from 'src/components/article/ArticleContent'

const CustomTextSectionInlineSettings = dynamic(() => import('./CustomTextSection.inline.settings'), { ssr: false })

export type CustomTextSectionProps = {
  isEditing?: boolean
  isFullWidth?: boolean
  paddingY?: number
  content?: string
}
const CustomTextSection: React.FC<CustomTextSectionProps> = ({ isEditing, isFullWidth, paddingY, content }) => {
  return (
    <div
      className={clsx({ container: !isFullWidth })}
      style={{
        paddingTop: paddingY,
        paddingBottom: paddingY,
      }}
    >
      {isEditing ? (
        <CustomTextSectionInlineSettings />
      ) : (
        <Skeleton loading={!content} className="py-5">
          <ArticleContent content={content ?? ''} />
        </Skeleton>
      )}
    </div>
  )
}

export default CustomTextSection
