export const notEmpty = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const randomCharacters = (digits = 8) => {
  return Math.random()
    .toString(36)
    .substring(13 - digits)
}

export const pascalCase = (str: string) =>
  `${str}`
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(new RegExp(/\s+(.)(\w+)/, 'g'), ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
    .replace(new RegExp(/\s/, 'g'), '')
    .replace(new RegExp(/\w/), s => s.toUpperCase())

export const formatPathOrExternalUrl = (externalUrl: string) => {
  let url: URL | null = null
  try {
    if (externalUrl.startsWith('http')) {
      url = new URL(externalUrl)
    } else if (externalUrl.startsWith('//')) {
      url = new URL(`https:${externalUrl}`)
    } else if (externalUrl.startsWith('/')) {
      return externalUrl
    } else {
      url = new URL(`https://${externalUrl}`)
    }
  } catch {}

  return url?.toString()
}
