'use client'

import React from 'react'
import { MerchandiseBriefProps } from 'src/types/merchandise'
import ProductCard, { ProductCardContent } from '../common/ProductCard'

const MerchandiseCard: React.FC<{
  merchandise: MerchandiseBriefProps
  showFavorite?: boolean
  openInNewTab?: boolean
  priority?: boolean
  isVisible?: boolean
}> = ({ merchandise, openInNewTab, showFavorite, priority, isVisible }) => {
  const merchandiseId = merchandise.id

  return (
    <ProductCard
      productType="Merchandise"
      productTarget={merchandiseId}
      productStatus={merchandise.status}
      featuredImageUrl={merchandise.featuredImageUrl}
      title={merchandise.title}
      path={merchandise.path}
      members={merchandise.seller ? [merchandise.seller] : []}
      openInNewTab={openInNewTab}
      showFavorite={showFavorite}
      priority={priority}
      isAuthorInfoHidden={merchandise.isAuthorInfoHidden}
      isVisible={isVisible}
    >
      <ProductCardContent
        listPrice={merchandise.listPrice}
        salePrice={null}
        averageRating={merchandise.averageRating}
        reviewCount={merchandise.reviewCount}
        isPriceHidden={merchandise.isPriceHidden}
        isParticipantCountHidden={merchandise.isParticipantCountHidden}
        isDiscountCountdownVisible={merchandise.isDiscountCountdownVisible}
      />
    </ProductCard>
  )
}

export default MerchandiseCard
