'use client'

import clsx from 'clsx'
import Link from 'next/link'
import PlaceholderImage from 'src/assets/images/image-placeholder.jpg'
import LazyLoadImage from 'src/components/common/LazyLoadImage'
import { styled } from 'styled-components'

export type ImageSectionProps = {
  isEditing?: boolean
  isFullWidth?: boolean
  paddingY?: number
  imageUrl?: string
  imageAlt?: string
  imageWidth?: number
  imageHeight?: number
  imageLink?: string
  isImageLinkOpenNewTab?: boolean
}
const StyledImageSection = styled.div`
  .image-wrapper {
    position: relative;
    width: 100%;
  }
  .image-wrapper img {
    width: 100%;
    height: auto;
  }
`
const ImageSection: React.FC<ImageSectionProps> = ({
  isEditing,
  isFullWidth,
  paddingY,
  imageUrl,
  imageAlt,
  imageWidth,
  imageHeight,
  imageLink,
  isImageLinkOpenNewTab,
}) => {
  const isImageLinkExternal = imageLink?.startsWith('http')
  const isImageValid = Boolean(imageUrl) && Boolean(imageWidth) && Boolean(imageHeight)
  const ImageBlock: React.FC = () => (
    <div className="image-wrapper">
      {isImageValid && imageUrl ? (
        <LazyLoadImage src={imageUrl} alt={imageAlt || ''} width={imageWidth} height={imageHeight} />
      ) : isEditing ? (
        <LazyLoadImage alt="" {...PlaceholderImage} />
      ) : null}
    </div>
  )

  return (
    <StyledImageSection
      className={clsx({ container: !isFullWidth })}
      style={{
        paddingTop: paddingY,
        paddingBottom: paddingY,
      }}
    >
      {imageLink ? (
        <Link
          href={imageLink}
          target={isImageLinkOpenNewTab ? '_blank' : '_self'}
          rel={isImageLinkExternal ? 'noopener noreferrer' : ''}
        >
          <ImageBlock />
        </Link>
      ) : (
        <ImageBlock />
      )}
    </StyledImageSection>
  )
}

export default ImageSection
