'use client'

import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledLoadingSection = styled.div<{ height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => props.height || 100}px;
`
const StyledSpin = styled(Spin)<{ color?: string | null }>`
  .ant-spin-dot-item {
    ${props => 'background-color: ' + props.color || props.theme['primary-color'] + ';'}
  }
`

const LoadingSection: React.FC<React.PropsWithChildren<{ height?: number }>> = ({ height }) => {
  return (
    <StyledLoadingSection height={height}>
      <StyledSpin size="large" />
    </StyledLoadingSection>
  )
}

export default LoadingSection
