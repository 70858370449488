'use client'

import { toolize } from '../..'
import CustomTextSection, { CustomTextSectionProps } from './CustomTextSection'

const CustomTextSectionToolize: React.FC<CustomTextSectionProps> = props => {
  return <CustomTextSection {...props} />
}

export default toolize(CustomTextSectionToolize)
