'use client'

import { useCourses } from 'src/hooks/course.client'
import { toolize } from '../..'
import CourseListSection, { CourseListSectionProps } from './CourseListSection'

const CourseListSectionToolize: React.FC<CourseListSectionProps> = ({ categoryId, ...props }) => {
  const { courses, loadingCourses } = useCourses({ categoryId, limit: 4 })
  return <CourseListSection {...props} courses={courses} loadingCourses={loadingCourses} />
}

export default toolize(CourseListSectionToolize)
