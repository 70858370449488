import { gql } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'

export const MERCHANDISE_DATE_COLUMNS = gql`
  fragment MERCHANDISE_DATE_COLUMNS on merchandise {
    fundraising_start_at
    fundraising_due_at
    preorder_due_at
    online_at
    offline_at
  }
`
export const MERCHANDISE_CATEGORY_COLUMNS = gql`
  fragment MERCHANDISE_CATEGORY_COLUMNS on merchandise {
    merchandise_categories {
      id
      category {
        id
        name
        slug
      }
    }
  }
`

export const GET_MERCHANDISES = gql`
  query GET_MERCHANDISES(
    $condition: merchandise_bool_exp
    $orderBy: [merchandise_order_by!]
    $limit: Int
    $offset: Int
  ) {
    merchandise(where: $condition, order_by: $orderBy, limit: $limit, offset: $offset) {
      id
      title
      type
      position
      slug
      featured_image_url
      cover_video_url
      is_private
      status
      seo_attributes

      is_price_hidden: metadata(path: "isPriceHidden")
      is_author_info_hidden: metadata(path: "isAuthorInfoHidden")
      is_participant_count_hidden: metadata(path: "isParticipantCountHidden")
      is_discount_countdown_visible: metadata(path: "isDiscountCountdownVisible")

      seller_id

      list_price
      fundraising_price
      preorder_price

      ...MERCHANDISE_DATE_COLUMNS
      ...MERCHANDISE_CATEGORY_COLUMNS

      seller {
        ...MEMBER_PUBLIC_COLUMNS
      }

      product_review_summary {
        review_count
        average_rating
      }

      merchandise_visibility {
        visibility_type
        purchasable_type
      }
    }

    merchandise_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
  }
  ${MERCHANDISE_DATE_COLUMNS}
  ${MERCHANDISE_CATEGORY_COLUMNS}
  ${MEMBER_PUBLIC_COLUMNS}
`
