'use client'

import { Pagination, Tabs } from 'antd'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import React, { useState } from 'react'
import { productPageSizeOptions } from 'src/configs/product'
import { useAuth } from 'src/contexts/AuthContext'
import { useArticleCollections, useMemberArticleCollectionCount } from 'src/hooks/article.client'
import { useCourseCollections } from 'src/hooks/course.client'
import { useProductDashboardPageParams } from 'src/hooks/product'
import { useMemberProductCollectionCount } from 'src/hooks/productCollection'
import styled from 'styled-components'
import ArticleRow from '../article/ArticleRow'
import CallToAction from '../common/CallToAction'
import FullLoadingItem from '../common/loading/FullLoadingItem'
import LoadingSection from '../common/loading/LoadingSection'
import CourseCard from '../course/CourseCard'

export const StyledArticleCard = styled.div`
  border-radius: 10px;
  box-shadow: var(--theme-box-shadow);
  color: var(--theme-default-text-color);
  background-color: var(--theme-component-background-color);
  width: 100%;
`
export const StyledArticleRowWrapper = styled.div`
  width: 100%;
  .cover-image {
    border-radius: 10px;
  }
  .text-box {
    align-self: stretch !important;
    justify-content: space-between;
  }
  .continue-reading-button {
    position: absolute;
    right: 16px;
    bottom: 0;
  }
`

const CourseSection: React.FC = () => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { pageSize } = useProductDashboardPageParams()
  const [currentPage, setCurrentPage] = useState(1)

  const { currentMemberId } = useAuth()
  const { loadingCourseCollections, courseCollections, courseCollectionCount } = useCourseCollections(currentMemberId, {
    statuses: ['fundraising', 'published'],
    limit: pageSize,
    offset: (currentPage - 1) * pageSize,
  })

  return (
    <div>
      {loadingCourseCollections ? (
        <FullLoadingItem />
      ) : courseCollections.length > 0 ? (
        <div className="row justify-content-center justify-content-sm-start">
          {courseCollections.map(course => (
            <div className="col-9 col-sm-4 col-lg-3 mt-4" key={course.id}>
              <CourseCard key={course.id} course={course} />
            </div>
          ))}
        </div>
      ) : (
        <CallToAction title="空空如也，趕快去逛逛吧" buttonText="探索課程" buttonHref="/courses" />
      )}

      <Pagination
        size="small"
        defaultCurrent={currentPage}
        pageSize={pageSize}
        total={courseCollectionCount}
        showSizeChanger={false}
        className="text-center"
        onChange={(page, pageSize) => {
          setCurrentPage(page)
          const params = new URLSearchParams(searchParams?.toString())
          params.set('pageSize', pageSize.toString())

          router.push(`${pathname}?${params.toString()}`)
        }}
      />
    </div>
  )
}

const ArticleSection: React.FC = () => {
  const { currentMemberId } = useAuth()

  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { pageSize } = useProductDashboardPageParams()
  const [currentPage, setCurrentPage] = useState(1)

  const { loadingArticles, articles, articleCount } = useArticleCollections(currentMemberId, {
    limit: pageSize,
    offset: currentPage * pageSize,
  })

  return (
    <div>
      {loadingArticles ? (
        <LoadingSection height={500} />
      ) : articles.length > 0 ? (
        <div className="row">
          {articles.map(article => (
            <StyledArticleCard key={article.id} className="mt-4 px-4 py-3 mx-5">
              <StyledArticleRowWrapper>
                <ArticleRow
                  article={article}
                  noCategory
                  showContinueReadingButton
                  showAbstract
                  datetimeFormat="YYYY-MM-DD"
                />
              </StyledArticleRowWrapper>
            </StyledArticleCard>
          ))}
        </div>
      ) : (
        <CallToAction title="空空如也，趕快去逛逛吧" buttonText="探索文章" buttonHref="/articles" />
      )}

      <Pagination
        size="small"
        showSizeChanger
        pageSizeOptions={productPageSizeOptions}
        defaultCurrent={currentPage}
        pageSize={pageSize}
        total={articleCount}
        className="text-center"
        onChange={(page, pageSize) => {
          setCurrentPage(page)
          const params = new URLSearchParams(searchParams?.toString())
          params.set('pageSize', pageSize.toString())

          router.push(`${pathname}?${params.toString()}`)
        }}
      />
    </div>
  )
}

const MyCollectionContentSection: React.FC<React.PropsWithChildren<{ tab: string }>> = ({ tab }) => {
  switch (tab) {
    case 'Course':
      return <CourseSection />
    case 'Article':
      return <ArticleSection />
    default:
      return null
  }
}

const MyCollectionSection: React.FC = () => {
  const [tab, setTab] = useState('Course')

  const { currentMemberId } = useAuth()
  const { memberProductCollectionCount: collectionCourseCount } = useMemberProductCollectionCount({
    memberId: currentMemberId,
    productType: 'Course',
  })
  const { memberArticleCollectionCount } = useMemberArticleCollectionCount({
    memberId: currentMemberId,
  })

  return (
    <div>
      <Tabs activeKey={tab} onChange={tab => setTab(tab)} className="mt-2 mt-sm-4 px-4 px-sm-0">
        <Tabs.TabPane tab={`課程 (${collectionCourseCount})`} key="Course" />
        <Tabs.TabPane tab={`文章 (${memberArticleCollectionCount})`} key="Article" />
      </Tabs>

      <MyCollectionContentSection tab={tab} />
    </div>
  )
}

export default MyCollectionSection
