'use client'

import clsx from 'clsx'
import dynamic from 'next/dynamic'
import React, { useMemo } from 'react'
import { CarouselSectionProps, StyledLoadingCarouselDiv } from 'src/components/carousel'
import { pascalCase } from 'src/helpers'
import { CarouselBlock, CarouselProps, CarouselType } from 'src/types/carousel'

export type CarouselPageSectionProps = {
  className?: string
  loadingCarousel?: boolean
  carousel?: CarouselProps | null
  isFullWidth?: boolean
  carouselId?: string
  type?: CarouselType
  block?: CarouselBlock
  isEditing?: boolean
  priority?: boolean
}
const CarouselPageSection: React.FC<CarouselPageSectionProps> = ({
  className: defaultClassName,
  loadingCarousel,
  carousel,
  isFullWidth,
  isEditing,
  carouselId,
  priority,
}) => {
  const carouselProps: CarouselSectionProps = useMemo(
    () => ({
      loading: loadingCarousel ?? false,
      slides: carousel?.slides ?? [],
      carouselOptions: {
        showDots: !carousel?.metadata.isPaginationDisabled,
        showArrow: !carousel?.metadata.isNavigationDisabled,
      },
    }),
    [carousel, loadingCarousel],
  )

  const className = useMemo(
    () =>
      clsx(defaultClassName, {
        container: !isFullWidth,
      }),
    [defaultClassName, isFullWidth],
  )
  const Carousel = useMemo(() => {
    const carouselType = carousel ? pascalCase(carousel.type) : 'Default'
    return dynamic<CarouselSectionProps>(
      () => {
        switch (carouselType) {
          case 'ImageOnly':
            return import('../../../carousel/ImageOnlyCarousel')
          case 'ImageWithTextButton':
            return import('../../../carousel/ImageWithTextButtonCarousel')
          case 'ImageWithTextButtonVertical':
            return import('../../../carousel/ImageWithTextButtonVerticalCarousel')
          case 'ImageWithThumbnailList':
            return import('../../../carousel/ImageWithThumbnailListCarousel')
          default:
            return import('../../../carousel/DefaultCarousel')
        }
      },
      { ssr: false, loading: () => <StyledLoadingCarouselDiv className={className} type={carousel?.type} /> },
    )
  }, [carousel, className])

  if (!carouselId) {
    if (isEditing) {
      return (
        <div className={className}>
          <StyledLoadingCarouselDiv $isPlaceHolder type="image_with_text_button_vertical" />
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className={className}>
      <Carousel priority={priority} {...carouselProps} />
    </div>
  )
}

export default CarouselPageSection
