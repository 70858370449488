'use client'

import { defineMessages } from '@formatjs/intl'
import { Button } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import AppointmentCard from 'src/components/appointment/AppointmentCard'
import CardListBlock from 'src/components/blockLayout/CardListBlock'
import PageSection from 'src/components/blockLayout/PageSection'
import { AppointmentBriefProps } from 'src/hooks/appointment.client'
import { ListSectionProps } from '../ListSection/ListSection'

export type AppointmentListSectionProps = ListSectionProps & {
  categoryId?: string | null
  loadingAppointments?: boolean
  appointments?: AppointmentBriefProps[]
}

const intlMessages = defineMessages({
  allAppointments: {
    id: 'components.AppointmentListSection.title.allAppointments',
    defaultMessage: '所有預約',
  },
  showMore: {
    id: 'components.AppointmentListSection.label.showMore',
    defaultMessage: '顯示更多預約',
  },
})

const AppointmentListSection: React.FC<AppointmentListSectionProps> = ({
  loadingAppointments,
  appointments = [],
  responsiveOptions,
  title,
  buttonText,
  buttonPosition = 'right',
  buttonSize = 'middle',
  listAlignment = 'alignLeft',
}) => {
  const { formatMessage } = useIntl()
  if (!loadingAppointments && appointments.length === 0) {
    return null
  }

  return (
    <PageSection
      loading={loadingAppointments}
      center
      title={title || formatMessage(intlMessages.allAppointments)}
      extra={
        buttonPosition !== 'bottom' ? (
          <Button href="/appointments" shape="round" size={buttonSize}>
            <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
            <span className="ml-1">»</span>
          </Button>
        ) : undefined
      }
      extraPosition={buttonPosition === 'bottom' ? undefined : buttonPosition}
    >
      <CardListBlock
        className="mt-3 mt-sm-4"
        responsiveOptions={responsiveOptions}
        listAlignment={listAlignment}
        loading={loadingAppointments}
        dataSource={appointments}
        render={(appointment, index) => <AppointmentCard appointment={appointment} priority={index === 0} />}
      />

      {buttonPosition === 'bottom' && (
        <div className="text-center mt-4">
          <Button href="/appointments" shape="round" size={buttonSize}>
            <div className="px-3">
              <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
              <span className="ml-1">»</span>
            </div>
          </Button>
        </div>
      )}
    </PageSection>
  )
}

export default AppointmentListSection
