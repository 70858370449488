import { gql, useQuery } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'
import { GET_PRODUCTS, mapProductFromGql, ProductProps } from '@havppen/gql/src/product'
import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { ProductStatus } from '@havppen/types/src/product'
import { notEmpty } from '@havppen/utils/src/array'
import { useParams, useSearchParams } from 'next/navigation'
import { useRouter as usePageRouter } from 'next/router'
import { useContext, useMemo } from 'react'
import { useAuth } from 'src/contexts/AuthContext'
import PreviewContext from 'src/contexts/PreviewContext'
import { GET_PRODUCT_POSSESSION, GET_PRODUCT_POSSESSION_AGGREGATE_COUNT } from 'src/gql/product'
import { MemberPublicBriefProps } from 'src/types/member'
import { NIL as NIL_UUID, validate as isUUID } from 'uuid'

export const useProductListPageParams = () => {
  const params = useParams()
  const searchParams = useSearchParams()
  const pageRouter = usePageRouter()

  const categorySlugs = (params?.slugs ?? pageRouter.query?.slugs ?? []) as string[]
  const parentCategorySlug = categorySlugs.length > 1 ? categorySlugs[categorySlugs.length - 2] : undefined
  const currentCategorySlug = categorySlugs[categorySlugs.length > 0 ? categorySlugs.length - 1 : 0] as
    | string
    | undefined
  const status = searchParams?.get('status') as ProductStatus | undefined
  const search = searchParams?.get('search')
  const orderBy = searchParams?.get('orderBy') as any | undefined
  const couponId = searchParams?.get('couponId')

  const pageSize = parseInt(searchParams?.get('pageSize') || '12')
  const currentPage = parseInt(searchParams?.get('page') || '1')

  return {
    categorySlugs,
    parentCategorySlug,
    currentCategorySlug,
    isCurrentSubcategory: categorySlugs.length > 1,
    status,
    search,
    orderBy,
    couponId,
    pageSize,
    currentPage,
  }
}

export const useProductDashboardPageParams = () => {
  const searchParams = useSearchParams()

  const status = searchParams?.get('status') as ProductStatus | undefined
  const search = searchParams?.get('search')
  const orderBy = searchParams?.get('orderBy') as any | undefined

  const pageSize = parseInt(searchParams?.get('pageSize') || '12')
  const currentPage = parseInt(searchParams?.get('page') || '1')

  return {
    status,
    search,
    orderBy,
    pageSize,
    currentPage,
  }
}

export const useIsProductPurchased = (params: { productType: string; productTargetOrSlug: string }) => {
  const isId = isUUID(params.productTargetOrSlug)

  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_PRODUCT_POSSESSION,
    types.GET_PRODUCT_POSSESSIONVariables
  >(GET_PRODUCT_POSSESSION, {
    context: { important: true },
    skip: currentMemberId === NIL_UUID,
    variables: {
      condition: {
        product_type: { _eq: params.productType },
        product_target: isId ? { _eq: params.productTargetOrSlug } : undefined,
        product: isId ? undefined : { slug: { _eq: params.productTargetOrSlug } },
        member_id: { _eq: currentMemberId },
        is_expired: { _eq: false },
      },
    },
  })

  const isPurchased: boolean = useMemo(() => (!data ? false : data.materialized_product_possession.length > 0), [data])

  return {
    loadingIsProductPurchased: loading,
    errorIsProductPurchased: error,
    isPurchased,
    refetchIsProductPurchased: refetch,
  }
}

export const useIsProductsPurchased = (productIds: string[]) => {
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_PRODUCT_POSSESSION_AGGREGATE_COUNT,
    types.GET_PRODUCT_POSSESSION_AGGREGATE_COUNTVariables
  >(GET_PRODUCT_POSSESSION_AGGREGATE_COUNT, {
    skip: currentMemberId === NIL_UUID || productIds.length === 0,
    context: { important: true },
    variables: {
      condition: {
        product_id: { _in: productIds },
        member_id: { _eq: currentMemberId },
        is_expired: { _eq: false },
      },
    },
  })

  const isPurchased: boolean =
    loading || error || !data
      ? false
      : (data.materialized_product_possession_aggregate.aggregate?.count || 0) === productIds.length

  return {
    loadingIsProductPurchased: loading,
    errorIsProductPurchased: error,
    isPurchased,
    refetchIsProductPurchased: refetch,
  }
}

export const useProductPurchaseCount = (params: { productType: string; productTargetOrSlug: string }) => {
  const isId = isUUID(params.productTargetOrSlug)
  const { currentMemberId } = useAuth()
  const { loading, error, data, refetch } = useQuery<
    types.GET_PRODUCT_POSSESSION_AGGREGATE_COUNT,
    types.GET_PRODUCT_POSSESSION_AGGREGATE_COUNTVariables
  >(GET_PRODUCT_POSSESSION_AGGREGATE_COUNT, {
    skip: currentMemberId === NIL_UUID,
    context: { important: true },
    variables: {
      condition: {
        product: {
          type: { _eq: params.productType },
          target: isId ? { _eq: params.productTargetOrSlug } : undefined,
          slug: isId ? undefined : { _eq: params.productTargetOrSlug },
        },
        member_id: { _eq: currentMemberId },
        is_expired: { _eq: false },
      },
    },
  })

  return {
    loadingProductPurchaseCount: loading,
    errorProductPurchaseCount: error,
    productPurchaseCount: data?.materialized_product_possession_aggregate.aggregate?.count || 0,
    refetchProductPurchaseCount: refetch,
  }
}

export const useProductOwners = (productId: string) => {
  const { loading, error, data, refetch } = useQuery<types.GET_PRODUCT_OWNERS, types.GET_PRODUCT_OWNERSVariables>(
    gql`
      query GET_PRODUCT_OWNERS($productId: String!) {
        product_owner(where: { product_id: { _eq: $productId } }) {
          member_id
          member {
            ...MEMBER_PUBLIC_COLUMNS
          }
        }
      }
      ${MEMBER_PUBLIC_COLUMNS}
    `,
    { variables: { productId } },
  )

  const productOwners: MemberPublicBriefProps[] = useMemo(
    () =>
      !data
        ? []
        : data.product_owner
            .map(product_owner => product_owner?.member)
            .filter(notEmpty)
            .map(member => mapMemberPublicFromGql(member))
            .filter(notEmpty),
    [data],
  )

  return {
    productOwners,
    loadingProductOwners: loading,
    errorProductOwners: error,
    refetchProductOwners: refetch,
  }
}

export const useProducts = (productIds: string[]) => {
  const { loading, error, data, refetch } = useQuery<types.GET_PRODUCTS, types.GET_PRODUCTSVariables>(GET_PRODUCTS, {
    skip: productIds.length === 0,
    variables: {
      condition: {
        id: { _in: productIds },
      },
    },
  })

  const products: ProductProps[] = useMemo(() => (data?.product || []).map(mapProductFromGql).filter(notEmpty), [data])
  return {
    products,
    loadingProducts: loading,
    errorProducts: error,
    refetchProducts: refetch,
  }
}

export const useIsProductEditor = (productId: string, memberId: string) => {
  const { isPreviewPurchased } = useContext(PreviewContext)
  const { loading, error, data, refetch } = useQuery<types.GET_PRODUCT_EDITOR, types.GET_PRODUCT_EDITORVariables>(
    gql`
      query GET_PRODUCT_EDITOR($productId: String!, $memberId: String!) {
        product_editor(where: { product_id: { _eq: $productId }, member_id: { _eq: $memberId } }, limit: 1) {
          product_id
        }
      }
    `,
    {
      skip: memberId === NIL_UUID || !productId || isPreviewPurchased,
      variables: {
        productId,
        memberId,
      },
    },
  )

  const isProductEditor: boolean = useMemo(() => {
    if (isPreviewPurchased) {
      return true
    }

    if (!data) {
      return false
    }

    return data.product_editor.length > 0
  }, [data, isPreviewPurchased])

  return {
    loadingIsProductEditor: loading,
    errorIsProductEditor: error,
    isProductEditor,
    refetchIsProductEditor: refetch,
  }
}
