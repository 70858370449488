'use client'

import { defineMessages } from '@formatjs/intl'
import { Button, Skeleton } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import ArticleRow from 'src/components/article/ArticleRow'
import { CardListResponsiveOptions } from 'src/components/blockLayout/CardListBlock'
import PageSection from 'src/components/blockLayout/PageSection'
import SpacedCarousel from 'src/components/common/carousel/SpacedCarousel'
import { StyledArticleRowWrapper } from 'src/components/mine/MyCollectionSection'
import { BREAK_POINT_SM, Responsive } from 'src/contexts/ResponsiveContext'
import { ArticleBasicProps } from 'src/types/article'
import { styled } from 'styled-components'

const intlMessages = defineMessages({
  title: {
    id: 'components.ArticleListSection.title',
    defaultMessage: '精選文章',
  },
  subtitle: {
    id: 'components.ArticleListSection.subtitle',
    defaultMessage: '為您精選的最新文章',
  },
  showMore: {
    id: 'components.ArticleListSection.label.showMore',
    defaultMessage: '閱讀更多',
  },
})

const StyledArticleCard = styled.div`
  position: relative;
  border-radius: var(--default-border-radius, 16px);
  box-shadow: var(--theme-box-shadow);
  background-color: var(--theme-component-background-color);
  width: 100%;
  height: 100%;
  min-height: 380px;
  max-width: var(--card-max-width);
  padding: 15px 20px;
  margin: 0 auto;

  @media screen and (min-width: ${BREAK_POINT_SM}px) {
    height: auto;
    max-width: unset;
    min-height: unset;
  }
`
const StyledMobileBlock = styled.div`
  width: 100%;
  height: 100%;
`

export type ArticleListSectionProps = {
  loadingArticles?: boolean
  articles?: ArticleBasicProps[]
  responsiveOptions?: CardListResponsiveOptions
  title?: string
  subtitle?: string
  buttonText?: string
  buttonPosition?: 'left' | 'bottom' | 'right'
  buttonSize?: 'small' | 'middle' | 'large'
  isFullWidth?: boolean
  articleCategoryId?: string
}
const ArticleListSection: React.FC<ArticleListSectionProps> = ({
  loadingArticles,
  articles = [],
  title,
  subtitle,
  buttonText,
  buttonSize = 'middle',
  isFullWidth,
}) => {
  const { formatMessage } = useIntl()
  if (!loadingArticles && articles.length === 0) {
    return null
  }

  return (
    <PageSection
      loading={loadingArticles}
      center
      title={title || formatMessage(intlMessages.title)}
      subtitle={subtitle || formatMessage(intlMessages.subtitle)}
    >
      <Responsive.Media greaterThanOrEqual="md">
        <div className="container mt-4">
          <Skeleton loading={loadingArticles}>
            <div className="row justify-content-center">
              {articles.map(article => (
                <div className={isFullWidth ? 'col-12' : 'col-12 col-lg-9'} key={article.id}>
                  <StyledArticleCard className="px-3 py-3 mb-4">
                    <StyledArticleRowWrapper>
                      <ArticleRow
                        article={article}
                        noCategory
                        showContinueReadingButton
                        showAbstract
                        datetimeFormat="YYYY-MM-DD"
                      />
                    </StyledArticleRowWrapper>
                  </StyledArticleCard>
                </div>
              ))}
            </div>
          </Skeleton>
        </div>
      </Responsive.Media>

      <Responsive.Media lessThan="md">
        <div className="mt-3">
          <Skeleton loading={loadingArticles}>
            {!loadingArticles && articles.length > 0 && (
              <>
                {articles.length > 1 ? (
                  <SpacedCarousel
                    dataSource={articles}
                    showArrow={false}
                    render={article => (
                      <StyledMobileBlock key={article.id} className="py-2 px-2 px-sm-0">
                        <StyledArticleCard>
                          <StyledArticleRowWrapper>
                            <ArticleRow
                              article={article}
                              noCategory
                              showContinueReadingButton
                              showAbstract
                              datetimeFormat="YYYY-MM-DD"
                            />
                          </StyledArticleRowWrapper>
                        </StyledArticleCard>
                      </StyledMobileBlock>
                    )}
                    showDots
                    dotsClassName="mt-3"
                    loop
                  />
                ) : (
                  <StyledMobileBlock className="py-2">
                    <StyledArticleCard>
                      <StyledArticleRowWrapper>
                        <ArticleRow
                          article={articles[0]}
                          noCategory
                          showContinueReadingButton
                          showAbstract
                          datetimeFormat="YYYY-MM-DD"
                        />
                      </StyledArticleRowWrapper>
                    </StyledArticleCard>
                  </StyledMobileBlock>
                )}
              </>
            )}
          </Skeleton>
        </div>
      </Responsive.Media>

      <div className="text-center mt-4">
        <Button href="/articles" shape="round" size={buttonSize}>
          <div className="px-3">
            <span>{buttonText || formatMessage(intlMessages.showMore)}</span>
            <span className="ml-1">»</span>
          </div>
        </Button>
      </div>
    </PageSection>
  )
}

export default ArticleListSection
