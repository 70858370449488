import { gql, useQuery } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'
import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import { MerchandiseType } from '@havppen/types/src/merchandise'
import { ProductStatus } from '@havppen/types/src/product'
import { useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { GET_MERCHANDISES, MERCHANDISE_CATEGORY_COLUMNS, MERCHANDISE_DATE_COLUMNS } from 'src/gql/merchandise'
import {
  MerchandiseBriefProps,
  MerchandiseOptionProps,
  MerchandiseOptionStockProps,
  MerchandiseProps,
  MerchandiseVariantOptionsProps,
} from 'src/types/merchandise'
import { validate as isUUID } from 'uuid'
import {
  formatGqlMerchandises,
  mapMerchandisesQueryOptionsToGqlVariables,
  MerchandiseQueryOptions,
} from './merchandise'

export const useMerchandises = (options?: MerchandiseQueryOptions) => {
  const { id: appId } = useApp()
  const { isAuthenticated, currentMemberId } = useAuth()

  const { loading, error, data, refetch } = useQuery<types.GET_MERCHANDISES, types.GET_MERCHANDISESVariables>(
    GET_MERCHANDISES,
    {
      variables: mapMerchandisesQueryOptionsToGqlVariables(
        {
          isAuthenticated,
          currentMemberId,
          ...options,
        },
        { appId },
      ),
    },
  )

  const merchandises: MerchandiseBriefProps[] = useMemo(() => {
    return formatGqlMerchandises(data)
  }, [data])
  return {
    merchandiseCount: data?.merchandise_aggregate.aggregate?.count || 0,
    loadingMerchandises: loading,
    merchandises,
    errorMerchandises: error,
    refetchMerchandises: refetch,
  }
}

export const useMerchandise = (merchandiseIdOrSlug: string) => {
  const isMerchandiseId = isUUID(merchandiseIdOrSlug)
  const { loading, error, data, refetch } = useQuery<types.GET_MERCHANDISE, types.GET_MERCHANDISEVariables>(
    GET_MERCHANDISE,
    {
      variables: {
        condition: {
          id: isMerchandiseId ? { _eq: merchandiseIdOrSlug } : undefined,
          slug: isMerchandiseId ? undefined : { _eq: merchandiseIdOrSlug },
        },
      },
    },
  )

  const merchandise: MerchandiseProps | null = useMemo(() => {
    if (!data || data.merchandise.length === 0) {
      return null
    }

    return data.merchandise.map(merchandise => ({
      id: merchandise.id,
      title: merchandise.title,
      subtitle: merchandise.subtitle,
      path: `/merchandise/${merchandise.slug ?? merchandise.id}`,
      type: merchandise.type as MerchandiseType,
      abstract: merchandise.abstract,
      content: merchandise.content,
      imageUrls: merchandise.image_urls,
      featuredImageUrl: merchandise.featured_image_url,
      coverVideoUrl: merchandise.cover_video_url,

      isPhysical: merchandise.is_physical ?? true,
      isPrivate: merchandise.is_private ?? false,
      isPriceHidden: merchandise.metadata?.isPriceHidden ?? false,
      isAuthorInfoHidden: merchandise.metadata?.isAuthorInfoHidden ?? false,
      isParticipantCountHidden: merchandise.metadata?.isParticipantCountHidden ?? false,
      isDiscountCountdownVisible: merchandise.metadata?.isDiscountCountdownVisible ?? false,

      reviewCount: merchandise.product_review_summary?.review_count ?? null,
      averageRating: merchandise.product_review_summary?.average_rating ?? null,
      purchaseCount: 0,

      categories: merchandise.merchandise_categories.map(merchandise_category => merchandise_category.category),
      status: merchandise.status as ProductStatus,
      position: merchandise.position,
      slug: merchandise.slug,
      metadata: merchandise.metadata,
      variations: merchandise.variations || [],
      shipping: merchandise.shipping,
      seller: mapMemberPublicFromGql(merchandise.seller),
      sellerId: merchandise.seller_id,
      listPrice: merchandise.list_price,
      preorderPrice: merchandise.preorder_price,
      fundraisingPrice: merchandise.fundraising_price,
      fundraisingStartAt: merchandise.fundraising_start_at ? new Date(merchandise.fundraising_start_at) : null,
      fundraisingDueAt: merchandise.fundraising_due_at ? new Date(merchandise.fundraising_due_at) : null,
      preorderDueAt: merchandise.preorder_due_at ? new Date(merchandise.preorder_due_at) : null,
      onlineAt: merchandise.online_at ? new Date(merchandise.online_at) : null,
      offlineAt: merchandise.offline_at ? new Date(merchandise.offline_at) : null,
      options: merchandise.merchandise_options.map(merchandise_option => {
        const lockedStock = merchandise_option.order_merchandise_option_summaries.reduce(
          (sum, order_merchandise_option_summary) => sum + order_merchandise_option_summary.quantity,
          0,
        )

        return {
          id: merchandise_option.id,
          sku: merchandise_option.sku,
          name: merchandise_option.name,
          stock: merchandise_option.stock ? merchandise_option.stock - lockedStock : -1,
          weightKg: merchandise_option.weight_kg,
          listPrice: merchandise_option.list_price,
          metadata: merchandise_option.metadata,
        }
      }),
    }))[0]
  }, [data])
  return {
    loadingMerchandise: loading,
    merchandise,
    errorMerchandise: error,
    refetchMerchandise: refetch,
  }
}

export const useMerchandiseVariantOptions = (merchandiseId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MERCHANDISE_VARIATION_OPTIONS,
    types.GET_MERCHANDISE_VARIATION_OPTIONSVariables
  >(GET_MERCHANDISE_VARIATION_OPTIONS, {
    variables: { merchandiseId },
  })

  const merchandiseVariantOptions: MerchandiseVariantOptionsProps | null = useMemo(() => {
    if (!data?.merchandise_by_pk) {
      return null
    }

    return {
      id: data.merchandise_by_pk.id,
      variations: data.merchandise_by_pk.variations,
      shipping: data.merchandise_by_pk.shipping,
      options: data.merchandise_by_pk.merchandise_options.map(merchandise_option => {
        const lockedStock = merchandise_option.order_merchandise_option_summaries.reduce(
          (sum, order_merchandise_option_summary) => sum + order_merchandise_option_summary.quantity,
          0,
        )

        return {
          id: merchandise_option.id,
          sku: merchandise_option.sku,
          name: merchandise_option.name,
          stock: merchandise_option.stock ? merchandise_option.stock - lockedStock : -1,
          weightKg: merchandise_option.weight_kg,
          listPrice: merchandise_option.list_price,
          metadata: merchandise_option.metadata,
        }
      }),
    }
  }, [data])
  return {
    loadingMerchandiseVariantOptions: loading,
    merchandiseVariantOptions,
    errorMerchandiseVariantOptions: error,
    refetchMerchandiseVariantOptions: refetch,
  }
}

export const useMerchandiseOptions = (options?: { merchandiseId?: string; merchandiseOptionIds?: string[] }) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MERCHANDISE_OPTIONS,
    types.GET_MERCHANDISE_OPTIONSVariables
  >(GET_MERCHANDISE_OPTIONS, {
    variables: {
      condition: {
        merchandise_id: options?.merchandiseId ? { _eq: options.merchandiseId } : undefined,
        id: options?.merchandiseOptionIds ? { _in: options?.merchandiseOptionIds } : undefined,
      },
    },
  })

  const merchandiseOptions: MerchandiseOptionProps[] = useMemo(() => {
    if (!data) {
      return []
    }

    return data.merchandise_option.map(merchandise_option => ({
      id: merchandise_option.id,
      sku: merchandise_option.sku,
      name: merchandise_option.name,
      stock: merchandise_option.stock ?? -1,
      weightKg: merchandise_option.weight_kg,
      listPrice: merchandise_option.list_price,
      metadata: merchandise_option.metadata,
    }))
  }, [data])
  return {
    loadingMerchandiseOptions: loading,
    merchandiseOptions,
    errorMerchandiseOptions: error,
    refetchMerchandiseOptions: refetch,
  }
}

export const useMerchandiseOption = (merchandiseOptionId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MERCHANDISE_OPTION,
    types.GET_MERCHANDISE_OPTIONVariables
  >(GET_MERCHANDISE_OPTION, {
    variables: { merchandiseOptionId },
  })

  const merchandiseOption: MerchandiseOptionProps | null = useMemo(
    () =>
      loading || error || !data?.merchandise_option_by_pk
        ? null
        : {
            id: data.merchandise_option_by_pk.id,
            sku: data.merchandise_option_by_pk.sku,
            name: data.merchandise_option_by_pk.name,
            stock: data.merchandise_option_by_pk.stock ?? -1,
            weightKg: data.merchandise_option_by_pk.weight_kg,
            listPrice: data.merchandise_option_by_pk.list_price,
            metadata: data.merchandise_option_by_pk.metadata,
          },
    [data, error, loading],
  )
  return {
    loadingMerchandiseOption: loading,
    merchandiseOption,
    errorMerchandiseOption: error,
    refetchMerchandiseOption: refetch,
  }
}

export const useMerchandiseOptionStocks = (merchandiseId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_MERCHANDISE_OPTION_STOCKS,
    types.GET_MERCHANDISE_OPTION_STOCKSVariables
  >(
    gql`
      query GET_MERCHANDISE_OPTION_STOCKS($merchandiseId: uuid!) {
        merchandise_option(where: { merchandise_id: { _eq: $merchandiseId } }) {
          id
          stock
          order_merchandise_option_summaries(where: { is_locked: { _eq: true } }) {
            quantity
          }
        }
      }
    `,
    { variables: { merchandiseId } },
  )

  const merchandiseOptionStocks: MerchandiseOptionStockProps[] = useMemo(() => {
    if (!data) {
      return []
    }

    return data.merchandise_option.map(merchandise_option => ({
      id: merchandise_option.id,
      stock:
        (merchandise_option.stock || 0) -
        merchandise_option.order_merchandise_option_summaries.reduce(
          (acc, order_merchandise_option_summary) => acc + order_merchandise_option_summary.quantity,
          0,
        ),
    }))
  }, [data])
  return {
    loadingMerchandiseOptionStocks: loading,
    errorMerchandiseOptionStocks: error,
    merchandiseOptionStocks,
    refetchMerchandiseOptionStocks: refetch,
  }
}

export const GET_MERCHANDISE = gql`
  query GET_MERCHANDISE($condition: merchandise_bool_exp!) {
    merchandise(where: $condition, limit: 1) {
      id
      title
      subtitle
      type
      abstract
      content
      position
      slug
      featured_image_url
      cover_video_url
      is_private
      status
      metadata

      variations
      shipping
      image_urls
      quantity_limit
      is_physical

      seller_id

      list_price
      fundraising_price
      preorder_price

      ...MERCHANDISE_DATE_COLUMNS
      ...MERCHANDISE_CATEGORY_COLUMNS

      seller {
        ...MEMBER_PUBLIC_COLUMNS
      }

      product_review_summary {
        review_count
        average_rating
      }

      merchandise_options {
        id
        sku
        name
        list_price
        stock
        weight_kg
        metadata
        order_merchandise_option_summaries(where: { is_locked: { _eq: true } }) {
          quantity
        }
      }

      merchandise_visibility {
        visibility_type
        purchasable_type
      }
    }
  }
  ${MERCHANDISE_DATE_COLUMNS}
  ${MERCHANDISE_CATEGORY_COLUMNS}
  ${MEMBER_PUBLIC_COLUMNS}
`

export const GET_MERCHANDISE_VARIATION_OPTIONS = gql`
  query GET_MERCHANDISE_VARIATION_OPTIONS($merchandiseId: uuid!) {
    merchandise_by_pk(id: $merchandiseId) {
      id
      variations
      shipping
      merchandise_options {
        id
        sku
        name
        list_price
        stock
        weight_kg
        metadata
        order_merchandise_option_summaries(where: { is_locked: { _eq: true } }) {
          quantity
        }
      }
    }
  }
`

export const GET_MERCHANDISE_OPTIONS = gql`
  query GET_MERCHANDISE_OPTIONS($condition: merchandise_option_bool_exp) {
    merchandise_option(where: $condition) {
      id
      sku
      name
      list_price
      stock
      weight_kg
      metadata
    }
  }
`

export const GET_MERCHANDISE_OPTION = gql`
  query GET_MERCHANDISE_OPTION($merchandiseOptionId: uuid!) {
    merchandise_option_by_pk(id: $merchandiseOptionId) {
      id
      sku
      name
      list_price
      stock
      weight_kg
      metadata
    }
  }
`
